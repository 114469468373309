import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import ContentHeader from "../components/layouts/ContentHeader";
import LiveMap from "../components/LiveMap";
import DashboardPieChart from "../components/charts/DashboardPieChart";
// import DashboardLineChart from "../components/charts/DashboardLineChart";
// import DashboardLineChartByCounty from "../components/charts/DashboardLineChartByCounty";
import {getStatistics, getAccidentPerCounty, getAccidentPerMonth, getPersonInCountyPerGender, getPersonInCountyPerCondition} from "../redux/actions/statisticsAction";
import Loader from "../components/Loader";
import DashboardBarChart from "../components/charts/DashboardBarChart";
import DashboardPersonConditionBarChart from "../components/charts/DashboardPersonConditionBarChart";
import DashboardPersonGenderBarChart from "../components/charts/DashboardPersonGenderBarChart";
import { getAllPersons } from "../redux/actions/personsAction";

const Index = () => {
  const dispatch = useDispatch();
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const {
    totalPerson,
    totalLicenses,
    totalMaleLicense,
    totalFemaleLicense,
    totalRegisterVehicles,
    totalVehicles,
    totalInjuries,
    totalFatalities,
    totalAccidents,
    loading,
    accidentPerMonth,
    accidentPerCounty,
    totalPersonInCountyPerGender,
    totalPersonInCountyPerCondition
  } = useSelector((state) => state.statisticReducer);
  const { persons } = useSelector(state => state.personsReducer)

  useEffect(() => {
    dispatch(getAllPersons());
    dispatch(getStatistics());
    dispatch(getAccidentPerCounty());
    dispatch(getAccidentPerMonth());
    dispatch(getPersonInCountyPerGender());
    dispatch(getPersonInCountyPerCondition());
  }, [dispatch]);

  return (
    <Fragment>
      <ContentHeader title="Accident Crash v1" content="Dashboard" />

      {/* Info boxes */}
      <div className="row">
        <div className="col-12 col-sm-6 col-md-3">
          <div className="info-box">
            <span className="info-box-icon bg-info elevation-1">
              <i className="fas fa-car" />
            </span>
            <div className="info-box-content">
              <span className="info-box-text">Accidents</span>
              <span className="info-box-number">
                {loading? 'Loading...' :totalAccidents}
                {/* <small>%</small> */}
              </span>
            </div>
            {/* /.info-box-content */}
          </div>
          {/* /.info-box */}
        </div>
        {/* /.col */}
        <div className="col-12 col-sm-6 col-md-3">
          <div className="info-box mb-3">
            <span className="info-box-icon bg-danger elevation-1">
              <i className="fas fa-car-crash" />
            </span>
            <div className="info-box-content">
              <span className="info-box-text">Vehivles Involved</span>
              <span className="info-box-number">{loading ? 'Loading...' :totalVehicles}</span>
            </div>
            {/* /.info-box-content */}
          </div>
          {/* /.info-box */}
        </div>
        {/* /.col */}
        {/* fix for small devices only */}
        <div className="clearfix hidden-md-up" />
        <div className="col-12 col-sm-6 col-md-3">
          <div className="info-box mb-3">
            <span className="info-box-icon bg-success elevation-1">
              <i className="fas fa-hospital-user" />
            </span>
            <div className="info-box-content">
              <span className="info-box-text">People involved</span>
              <span className="info-box-number">{persons.length}</span>
            </div>
            {/* /.info-box-content */}
          </div>
          {/* /.info-box */}
        </div>
        {/* /.col */}
        <div className="col-12 col-sm-6 col-md-3">
          <div className="info-box mb-3">
            <span className="info-box-icon bg-warning elevation-1">
              <i className="fas fa-car" />
            </span>
            <div className="info-box-content">
              <span className="info-box-text">Registered Vehicles</span>
              <span className="info-box-number">{loading ? 'Loading':totalRegisterVehicles}</span>
            </div>
            {/* /.info-box-content */}
          </div>
          {/* /.info-box */}
        </div>
        {/* /.col */}
      </div>
      {/* /.row */}
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Monthly Recap Report</h5>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus" />
                </button>
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-tool dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <i className="fas fa-wrench" />
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    role="menu"
                  >
                    <Link to="#" className="dropdown-item">
                      Action
                    </Link>
                    <Link to="#" className="dropdown-item">
                      Another action
                    </Link>
                    <Link to="#" className="dropdown-item">
                      Something else here
                    </Link>
                    <Link className="dropdown-divider" />
                    <Link to="#" className="dropdown-item">
                      Separated link
                    </Link>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="remove"
                >
                  <i className="fas fa-times" />
                </button>
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="row">
                <div className="col-md-8">
                  <LiveMap
                    mapstyle={{ width: "100%", height: "300px" }}
                    zoom={7}
                  />
                </div>
                {/* /.col */}
                <div className="col-md-4">
                  <p className="text-center">
                    <strong>DATA</strong>
                  </p>
                  <div className="progress-group">
                    People Involved/Dead
                    <span className="float-right">
                      <b>{ loading? 'Loading...' :totalPerson}</b>/{ loading? 'Loading...': totalFatalities }
                    </span>
                    <div className="progress progress-sm">
                      <div
                        className="progress-bar bg-danger"
                        style={{ width: "80%" }}
                      />
                    </div>
                  </div>
                  {/* /.progress-group */}
                  <div className="progress-group">
                    People Involved/injured
                    <span className="float-right">
                      <b>{ loading? 'Loading...' :totalPerson}</b>/{ loading? 'Loading...': totalInjuries }
                    </span>
                    <div className="progress progress-sm">
                      <div
                        className="progress-bar bg-warning"
                        style={{ width: "75%" }}
                      />
                    </div>
                  </div>
                  {/* /.progress-group */}
                  <div className="progress-group">
                    <span className="progress-text">
                      Register Vehicle/Active
                    </span>
                    <span className="float-right">
                      <b>{ loading? 'Loading...' : totalRegisterVehicles }</b>/97,478
                    </span>
                    <div className="progress progress-sm">
                      <div
                        className="progress-bar bg-success"
                        style={{ width: "60%" }}
                      />
                    </div>
                  </div>
                  {/* /.progress-group */}
                  <div className="progress-group">
                    Register Vehicle/Expired
                    <span className="float-right">
                      <b>151,700</b>/52,478
                    </span>
                    <div className="progress progress-sm">
                      <div
                        className="progress-bar bg-warning"
                        style={{ width: "50%" }}
                      />
                    </div>
                  </div>
                  {/* /.progress-group */}
                  <div className="progress-group">
                    Register Licenses/Active
                    <span className="float-right">
                      <b>57,700</b>/37,478
                    </span>
                    <div className="progress progress-sm">
                      <div
                        className="progress-bar bg-success"
                        style={{ width: "50%" }}
                      />
                    </div>
                  </div>
                  {/* /.progress-group */}
                  <div className="progress-group">
                    Register Licenses/Expired
                    <span className="float-right">
                      <b>57,700</b>/24,478
                    </span>
                    <div className="progress progress-sm">
                      <div
                        className="progress-bar bg-warning"
                        style={{ width: "50%" }}
                      />
                    </div>
                  </div>
                  {/* /.progress-group */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* ./card-body */}
            <div className="card-footer">
              <div className="row">
                <div className="col-sm-3 col-6">
                  <div className="description-block border-right">
                    {/* <span className="description-percentage text-success">
                      <i className="fas fa-caret-up" /> 17%
                    </span> */}
                    <h5 className="description-header">{ loading ? 'Loading...' : totalRegisterVehicles}</h5>
                    <span className="description-text">REGISTER VEHICLES</span>
                  </div>
                  {/* /.description-block */}
                </div>
                {/* /.col */}
                <div className="col-sm-3 col-6">
                  <div className="description-block border-right">
                    {/* <span className="description-percentage text-warning">
                      <i className="fas fa-caret-left" /> 32%
                    </span> */}
                    <h5 className="description-header">{ loading ? 'Loading...' : totalLicenses}</h5>
                    <span className="description-text">DRIVER LICENSE</span>
                  </div>
                  {/* /.description-block */}
                </div>
                {/* /.col */}
                <div className="col-sm-3 col-6">
                  <div className="description-block border-right">
                    {/* <span className="description-percentage text-success">
                      <i className="fas fa-caret-up" /> 20%
                    </span> */}
                    <h5 className="description-header">{  loading ? 'Loading...' : totalFemaleLicense}</h5>
                    <span className="description-text">
                      REGISTER FEMALE DRIVERS
                    </span>
                  </div>
                  {/* /.description-block */}
                </div>
                {/* /.col */}
                <div className="col-sm-3 col-6">
                  <div className="description-block">
                    {/* <span className="description-percentage text-danger">
                      <i className="fas fa-caret-down" /> 67%
                    </span> */}
                    <h5 className="description-header">{ loading ? 'Loading...' : totalMaleLicense}</h5>
                    <span className="description-text">
                      REGISTER MALE DRIVERS
                    </span>
                  </div>
                  {/* /.description-block */}
                </div>
              </div>
              {/* /.row */}
            </div>
            {/* /.card-footer */}
          </div>
          {/* /.card */}
        </div>
        {/* /.col */}
      </div>
      {/* /.row */}
      {/* Main row */}
      <div className="row">
        {/* Left col */}
        <div className="col-md-6">
          {/* MAP & BOX PANE */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Accidents Per Month </h3>
              <div className="card-tools">
                <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                >
                  <i className="fas fa-minus" />
                </button>
                {/* <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="remove"
                >
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body p-0">
              <div className="d-md-flex p-4" >
                {
                  loading? <Loader /> : <DashboardBarChart counts={accidentPerMonth} title="Accidents Per Month" labels={months}/>
                }
                {/* /.card-pane-right */}
              </div>
              {/* /.d-md-flex */}
            </div>
            {/* /.card-body */}
          </div>
        </div>
        {/* /.col */}
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Accidents Per County</h3>
              <div className="card-tools">
                <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                >
                  <i className="fas fa-minus" />
                </button>
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body p-4">
              <div className="d-md-flex">
                {
                  loading? <Loader /> : <DashboardPieChart  accidentPerCounty={accidentPerCounty} />
                }
                {/* /.card-pane-right */}
              </div>
              {/* /.d-md-flex */}
            </div>
            {/* /.card-body */}
          </div>
        </div>
        {/* /.col */}
      </div>
      {/* Main row */}
      <div className="row">
        {/* Left col */}
        <div className="col-md-6">
          {/* MAP & BOX PANE */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Total Fatalities, Injuries and Non Injured Per County</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus" />
                </button>
                {/* <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="remove"
                >
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body p-0">
              <div className="d-md-flex p-4" >
                {
                  loading? <Loader /> : <DashboardPersonConditionBarChart counts={totalPersonInCountyPerCondition} />
                }
                {/* /.card-pane-right */}
              </div>
              {/* /.d-md-flex */}
            </div>
            {/* /.card-body */}
          </div>
        </div>
        {/* /.col */}
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Total Male and Female per County</h3>
              <div className="card-tools">
                <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                >
                  <i className="fas fa-minus" />
                </button>
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body p-4">
              <div className="d-md-flex">
                {
                  loading? <Loader /> : <DashboardPersonGenderBarChart counts={totalPersonInCountyPerGender}/>
                }
                {/* /.card-pane-right */}
              </div>
              {/* /.d-md-flex */}
            </div>
            {/* /.card-body */}
          </div>
        </div>
        {/* /.col */}
      </div>
      {/* /.row */}
    </Fragment>
  );
};

export default Index;