import * as types from '../types'

const initialState = {
    reg_vehicles: [],
    vehicle: {},
    loading: true,
    error: null,
    isSuccess: false,
    total: 0,
    count: 0,
    numberOfPages: 1,
    pagination: {}
}

export const regVehicleReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_VEHICLES:
            return {
                ...state,
                reg_vehicles: action.payload,
                loading: false,
                error: null,
                isSuccess: true,
                count: action.count,
                pagination: action.pagination,
                numberOfPages: action.numberOfPages,
                total: action.total,
            }
        case types.GET_ONE_VEHICLE:
            return {
                ...state,
                vehicle: action.payload,
                loading: false,
                error: null,
                isSuccess: false
            }
        case types.SET_LOADING:
            return {
                ...state,
                loading: true,
                error: null
            }
        case types.SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case types.RESET:
            return {
                ...state,
                loading: false,
                isSuccess: false,
                error: null
            }
        default:
            return state
    }
}
