import * as types from '../types'

// get user from localStorage
const user = JSON.parse(localStorage.getItem('user'));

const initialState = {
    loggedInUser: user ? user : {},
    loggedIn: !!user,
    loading: false,
    resetPwd: false,
    error: null
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.USER_LOGIN:
            return {
                ...state,
                loggedInUser: action.payload,
                loggedIn: true,
                loading: false,
                error: null
            }
        case types.GET_LOGGED_IN_USER:
            return {
                ...state,
                loggedInUser: action.payload,
                loggedIn: true,
                loading: false,
                error: null
            }
        case types.LOG_OUT:
            return {
                ...state,
                loggedInUser: action.payload,
                loggedIn: false,
                loading: false,
                error: null
            }
        case types.RESET_PWD:
            return {
                ...state,
                resetPwd: true,
                loading: false,
                error: null
            }
        case types.SET_LOADING:
            return {
                ...state,
                loading: true,
                error:null
            }
        case types.SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case types.RESET:
            return {
                ...state,
                loading: false,
                resetPwd: false,
                error: null
            }
        default:
            return state
    }
}
