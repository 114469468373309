import * as types from '../types'

const initialState = {
    persons: [],
    person: {},
    loading: true,
    error: null,
    isSuccess: false,
}

export const personsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_PERSONS:
            return {
                ...state,
                persons: action.payload,
                loading: false,
                error: null,
                isSuccess: true
            }
        case types.GET_ONE_PERSON:
            return {
                ...state,
                person: action.payload,
                loading: false,
                error: null,
                isSuccess: false
            }
        default:
            return state
    }
}
