// users types
export const GET_USERS = 'GET_USERS'
export const CREATE_USER = 'CREATE_USER'
export const GET_ONE_USER = 'GET_ONE_USER'
export const EDIT_USER = 'EDIT_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER = 'DELETE_USER'

export const SET_LOADING = 'SET_LOADING'
export const SET_ERROR = 'SET_ERROR'
export const RESET = 'RESET'
export const LOGS_ERROR = 'LOGS_ERROR'

// STATISTICS TYPE
export const GET_STATISTICS = 'GET_STATISTICS'
export const GET_ACCIDENT_PER_MONTH = 'GET_ACCIDENT_PER_MONTH'
export const GET_ACCIDENT_PER_COUNTY = 'GET_ACCIDENT_PER_COUNTY'
export const GET_PERSONS_IN_COUNTY_PER_GENDER = 'GET_PERSONS_IN_COUNTY_PER_GENDER'
export const GET_PERSONS_IN_COUNTY_PER_CONDITION = 'GET_PERSONS_IN_COUNTY_PER_CONDITION'

// auth types
export const USER_LOGIN = 'USER_LOGIN'
export const LOG_OUT = 'LOG_OUT'
export const GET_LOGGED_IN_USER = 'GET_LOGGED_IN_USER'
export const RESET_PWD = 'RESET_PWD'

// accidents types
export const GET_ACCIDENTS = 'GET_ACCIDENTS'
export const GET_ONE_ACCIDENT = 'GET_ONE_ACCIDENT'
export const UPDATE_ACCIDENT = 'EDIT_ACCIDENT'
export const DELETE_ACCIDENT = 'DELETE_ACCIDENT'

// crashes types
export const GET_CRASHES = 'GET_CRASHES'
export const GET_ONE_CRASH = 'GET_ONE_CRASH'
export const EDIT_CRASH = 'EDIT_CRASH'
export const DELETE_CRASH = 'DELETE_CRASH'

// roads types
export const GET_ROADS = 'GET_ROADS'
export const GET_ONE_ROAD = 'GET_ONE_ROAD'
export const EDIT_ROAD = 'EDIT_ROAD'
export const DELETE_ROAD = 'DELETE_ROAD'

// persons types
export const GET_PERSONS = 'GET_PERSONS'
export const GET_ALL_PERSONS = 'GET_ALL_PERSONS'
export const GET_ONE_PERSON = 'GET_ONE_PERSON'
export const EDIT_PERSON = 'EDIT_PERSON'
export const DELETE_PERSON = 'DELETE_PERSON'

//vehicle types
export const GET_VEHICLES = 'GET_VEHICLES'
export const GET_ALL_VEHICLES = 'GET_ALL_VEHICLES'
export const GET_ONE_VEHICLE = 'GET_ONE_VEHICLE'

// license types
export const GET_LICENSES = 'GET_LICENSES'
export const GET_ONE_LICENSE = 'GET_ONE_LICENSE'

// Loggers

export const GET_ONE_LOG = 'GET_ONE_LOG'
export const GET_ALL_LOGS = 'GET_ALL_LOGS'