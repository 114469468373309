import * as types from '../types';
import { toast } from 'react-toastify';
import { getUserToken, prodAxios } from '../../utils/sysConfig';


// GET ALL ACCIDENTS
export const getAllPersons = () => async dispatch => {

    try {
        await prodAxios.get('/api/v1/persons', await getUserToken())
            .then((res) => {
                dispatch({
                    type: types.GET_ALL_PERSONS,
                    payload: res.data.data
                })
            }).catch((error) => {
                toast.error( error.response.data.error)
            })
    } catch (e) {
        toast.error( e.response.data.error)
    }

}