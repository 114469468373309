import { BrowserRouter, Routes, Route } from 'react-router-dom'; // REACT ROUTER DOM v6
import ProtectedRoute from './components/ProtectedRoute'; // Protected Route
import 'react-toastify/dist/ReactToastify.css'; // React Toastify CSS
import { ToastContainer } from 'react-toastify';
import { Fragment } from 'react';

// FILES   within the PAGES FOLDER
import {
    Dashboard,
    Login,
    Profile,
    Error,
    PageWrapper,
    AllUsers,
    AddUser,
    ShowUser,
    EditUser,
    AllAccidents,
    AccidentDetails,
    EditAccident,
    RegisterVehicles,
    RegisterVehicleDetails,
    AllLicenses,
    LicenseDetails,
    LiveMapPage,
    Logger
} from './pages';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {

    return (
        <Fragment>
            <BrowserRouter>
                {/* Dashboard */}
                <Routes>
                    <Route
                        path="/dashboard"
                        element={
                            <ProtectedRoute>
                                <PageWrapper />
                            </ProtectedRoute>
                        }
                    >
                        <Route index element={<Dashboard />} />
                        <Route path="live-map" exact element={<LiveMapPage />} />
                        <Route path="logs" exact element={<Logger />} />
                    </Route>

                    {/* User */}
                    <Route
                        path="/users"
                        exact
                        element={
                            <ProtectedRoute>
                                <PageWrapper />
                            </ProtectedRoute>
                        }
                    >
                        <Route index element={<AllUsers />} />
                        <Route path="addUser" exact element={<AddUser />} />
                        <Route path="edit/:id" exact element={<EditUser />} />
                        <Route path=":id" exact element={<ShowUser />} />
                        <Route path="profile" exact element={<Profile />} />
                    </Route>

                    {/* Accident */}
                    <Route
                        path="/accidents"
                        element={
                            <ProtectedRoute>
                                <PageWrapper />
                            </ProtectedRoute>
                        }
                    >
                        <Route index element={<AllAccidents />} />
                        <Route path=":id" element={<AccidentDetails />} />
                        <Route path="edit/:id" element={<EditAccident />} />
                    </Route>

                    {/* Vehicle Reg */}
                    <Route
                        path="/register-vehicles"
                        element={
                            <ProtectedRoute>
                                <PageWrapper />
                            </ProtectedRoute>
                        }
                    >
                        <Route index element={<RegisterVehicles />} />
                        <Route path=":id" element={<RegisterVehicleDetails />} />
                    </Route>

                    {/* LICENSE */}
                    <Route
                        path="/licenses"
                        element={
                            <ProtectedRoute>
                                <PageWrapper />
                            </ProtectedRoute>
                        }
                    >
                        <Route index element={<AllLicenses />} />
                        <Route path=":id" element={<LicenseDetails />} />
                    </Route>

                    {/* SIngle routes */}
                    <Route path="/" element={<Login />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                    <Route path="*" element={<Error />} />
                </Routes>
            </BrowserRouter>
            <ToastContainer />
        </Fragment>
    );
}

export default App;
