import React, {Fragment}from 'react'
import ContentHeader from '../components/layouts/ContentHeader'
import LiveMap from '../components/LiveMap';




const LiveMapPage = () => { 

  return (

    <Fragment>
      <ContentHeader title='Accident Crash DB v1' content='Live Map' />

      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body'>
            <LiveMap mapstyle={{width: '100%', height: '100vh'}} zoom={8}/>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}



export default LiveMapPage