import ContentWrapper from '../components/layouts/ContentWrapper'
import Sidebar from '../components/layouts/SideBar';
import Navbar from '../components/layouts/Navbar';
import Footer from '../components/layouts/Footer';
import {getMe} from '../redux/actions/authAction'
import {useDispatch} from "react-redux";
import {useEffect} from "react";


function PageWrapper() {
   const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getMe());
    },);

    return (
        <div className='wrapper'>
            <Navbar />
            <Sidebar />
            <ContentWrapper />
            <Footer />
        </div>
    )
}

export default PageWrapper
