import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector} from 'react-redux'

// The purpose of the below codes is to provide protection against unauthorized users. It will automatically redirect you to the homepage if you are not logged in to the dashboard.

const ProtectedRoute = ({ children }) => {
  const {loggedIn} = useSelector(state => state.authReducer);

  if (!loggedIn) {
    return <Navigate to="/" />;
  }
  return children;
};

export default ProtectedRoute;
