import React from 'react'
function Spinner() {
    return (
        <>
            <div className="card">
                <div className="spinnerBox">
                    <div className="loadingSpinner" />
                </div>
            </div>
            <br />
            <br />
        </>
    )
}

export default Spinner