import { getUser, reset, updateUser } from '../../redux/actions/usersAction'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getMe } from '../../redux/actions/authAction'
import React, { useEffect, useState } from 'react'
import Spinner from "../../components/Spinner";
import Modal from '../../components/Modal'
import { toast } from "react-toastify";
// import moment from 'moment'

const EditUser = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { id } = useParams()

    // create a loading local state
    const [updateLoading, setUpdateLoading] = useState(false);
    const [editUserState, setEditUserState] = useState({
        _id: "",
        firstName: "",
        middleName: "",
        lastName: "",
        phone: "",
        dob: "",
        email: "",
        agencies: "",
        status: "",
        role: "",
        photo: "",
    });

    const {
        firstName,
        middleName,
        lastName,
        phone,
        dob,
        email,
        agencies,
        status,
        role,
        photo,
    } = editUserState;

    // get loading, error, and users data from userReducer
    const { error, user, userAction } = useSelector(state => state.userReducer)
    const loggedInUser = useSelector(state => state.authReducer.loggedInUser.user);


    useEffect(() => {
        dispatch(getUser(id));
    }, [dispatch, id])

    useEffect(() => {
        if (error) {
            toast.error(error)
            setUpdateLoading(false)
            dispatch(reset())
        }
    }, [dispatch, error]);


    useEffect(() => {
        if (user) {
            setEditUserState({
                firstName: user.firstName,
                lastName: user.lastName,
                middleName: user.middleName,
                phone: user.phone,
                dob: user.dob,
                email: user.email,
                agencies: user.agencies,
                status: user.status,
                role: user.role,
                photo: user.photo,
            })
        }
    }, [user]);

    if (error) {
        toast.error(error)
    }
    
    // if(loading){
    //     return <Spinner />
    // }


    const handleOnChange = (e) => {
        setEditUserState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = (e) => {
        setUpdateLoading(true)
        e.preventDefault()
        const userData = {
            firstName,
            middleName,
            lastName,
            phone,
            dob,
            email,
            agencies,
            status,
            role,
            photo,
        }

        console.log(userData)
        dispatch(updateUser(id, userData))
        // dispatch(getMe())
    }

    if (userAction) {
        // redirect the login use to the profile if he is editing his own info or redirect to all users page is another user info
        if (loggedInUser._id === user._id) {
            navigate('/users/profile')
        } else {
            navigate('/users')
        }
    }

    return (
        user ?
            <>
                <br />
                <br />
                <section>
                    <div className="">
                        <div id="multple-step-form-n" className=" overflow-hidden">
                            <div id="multistep-start-row" className="row">
                                <div id="multistep-start-column" className="col-12 m-auto">
                                    <div
                                        id="single-form-next"
                                        className="multisteps-form__panel shadow p-4 rounded bg-white js-active"
                                        data-animation="scaleIn"
                                    >
                                        <form
                                            id="main-form"
                                            className="multisteps-form__form"
                                            encType="multipart/form-data"
                                        >
                                            <h3 className="multisteps-form__title">Edit User Form</h3>
                                            <div className="multisteps-form__content">
                                                <div id="input-grp-double" className="form-row mt-4">
                                                    <div className="col-12 col-sm-4">
                                                        {/* first name input field */}
                                                        <label>First Name </label>
                                                        <input
                                                            type="text"
                                                            name="firstName"
                                                            value={firstName}
                                                            onChange={handleOnChange}
                                                            className="form-control multisteps-form__input"
                                                            placeholder="Hannah"
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-4">
                                                        {/* middle name input field */}
                                                        <label>Middle Name</label>
                                                        <input
                                                            type="text"
                                                            name="middleName"
                                                            value={middleName}
                                                            onChange={handleOnChange}
                                                            className="form-control multisteps-form__input"
                                                            placeholder="Faith"
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-4">
                                                        {/* last name input field */}
                                                        <label>Last Name</label>
                                                        <input
                                                            type="text"
                                                            name="lastName"
                                                            value={lastName}
                                                            onChange={handleOnChange}
                                                            className="form-control multisteps-form__input"
                                                            placeholder="Richards"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="multisteps-form__content">
                                                <div id="input-grp-double-1" className="form-row mt-4">
                                                    <div className="col-12 col-sm-4">
                                                        {/* email input field */}
                                                        <label>Email</label>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            value={email}
                                                            onChange={handleOnChange}
                                                            className="form-control multisteps-form__input"
                                                            placeholder="hannahrichards@gmail.com"
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-4">
                                                        {/*phone input field */}
                                                        <label>Phone Number</label>
                                                        <input
                                                            type="tel"
                                                            name="phone"
                                                            value={phone}
                                                            onChange={handleOnChange}
                                                            className="form-control multisteps-form__input"
                                                            placeholder="0770777777"
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-4">
                                                        {/* phone input field */}
                                                        <label>Date of Birth</label>
                                                        <input
                                                            type="date"
                                                            name="dob"
                                                            value={dob}
                                                            onChange={handleOnChange}
                                                            className="form-control multisteps-form__input"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="multisteps-form__content">
                                                <div id="input-grp-double-4" className="form-row mt-4">
                                                    <div className="col-12 col-sm-4">
                                                        {/* county of deployment select field */}
                                                        <label>Agencies</label>
                                                        <select name="agencies" className="custom-select" id="agency" value={agencies} onChange={handleOnChange} >
                                                            {(loggedInUser.role === 'Dev') ? (
                                                                <>
                                                                    <option>Select</option>
                                                                    <option>LNP</option>
                                                                    <option>MPW</option>
                                                                    <option>MOT</option>
                                                                    <option>MOH</option>
                                                                </>
                                                            ) : <option>{agencies}</option>}
                                                        </select>
                                                    </div>
                                                    <div className="col-12 col-sm-4">
                                                        {/* status select field */}
                                                        <label>Status</label>
                                                        <select name="status" className="custom-select" id="status" value={status} onChange={handleOnChange} >
                                                            <option>Select</option>
                                                            <option>Active</option>
                                                            <option>Not Active</option>
                                                            <option>Suspended</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-12 col-sm-4">
                                                        {/* status select field */}
                                                        <label>Role</label>
                                                        <select name="role" className="custom-select" id="role" value={role} onChange={handleOnChange} >
                                                            <option>Select</option>
                                                            {(loggedInUser.role === 'Dev') ? (<option>Dev</option>) : null}
                                                            <option>Super Admin</option>
                                                            <option>Admin</option>
                                                            <option>DataEntry</option>
                                                            <option>User</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="multisteps-form__content">
                                                <div id="input-grp-double-4" className="form-row mt-4">
                                                    <div className="col-12 col-sm-4">
                                                        {/* officer photo input field */}
                                                        <label>Profile</label>
                                                        <input
                                                            className="form-control multisteps-form__input"
                                                            type="file"
                                                            name="photo"
                                                            onChange={handleOnChange}
                                                            value={photo}
                                                        // onChange={handleOfficerImage}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="multisteps-form__content">
                                                <br />
                                                {/*<div id="input-grp-double-2" className="form-row mt-4">*/}
                                                {updateLoading ?
                                                    <p
                                                        className="float-left btn btn-primary submitButton mb-2"
                                                    >
                                                        <i className="fa fa-spinner" />Loading...
                                                    </p>
                                                    :
                                                    <button
                                                        type="submit"
                                                        onClick={handleSubmit}
                                                        className="float-left btn btn-primary submitButton mb-2"
                                                    >
                                                        Update User
                                                    </button>
                                                }
                                                {loggedInUser.role === 'Admin' || loggedInUser.role === 'Dev' ?
                                                    <button className="float-right btn btn-danger" onClick={(e) => { e.preventDefault() }} data-toggle="modal" data-target="#modal-default">Delete <i className="fa fa-trash" /></button>
                                                    : null}
                                                <br />
                                                <br />
                                            </div>
                                        </form>
                                    </div>
                                    <Modal userId={user._id} userName={user.firstName + ' ' + user.middleName + ' ' + user.lastName} modalTitle="User" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
            : <Spinner />
    )
}

export default EditUser;
