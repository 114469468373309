import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Pagination = (
    firstReducer,
    secondReducer,
    thirdReducer,
    firstFunction,
    myUseState,
    myUseDispatch
) => {
    // set use dispatch
    const dispatch = myUseDispatch();

    const [pageNumber, setPageNumber] = useState(1);
    const [start, setStart] = myUseState(0);
    const [end, setEnd] = myUseState(0);
    const [search, setSearch] = myUseState("");
    const [limit, setLimit] = myUseState(25);

    // get all the data from this reducer
    const data = useSelector(firstReducer);

    // get loading, numberOfPages, error, and licenses from licenseReducer
    const { numberOfPages, pagination, error, loading, count, total } =
        useSelector(secondReducer);

    // store the number of pages in an array like this [1,2,3,4,5,6,7,8,9...]
    const pages = new Array(numberOfPages).fill(null).map((v, i) => i);
    // get login user from authReducer
    const loginUser = useSelector(thirdReducer);

    // const logInUser = user;

    useEffect(() => {
        // dispatch get all licenses function base on page number and set the start and end value
        dispatch(firstFunction(pageNumber, limit, search, ""));
        if (pageNumber < 5) {
            setStart(0);
        } else {
            setStart(pageNumber - 5);
        }

        if (pageNumber > numberOfPages - 5) {
            setEnd(numberOfPages + 10);
        } else if (pageNumber < 5) {
            setEnd(pageNumber + 9);
        } else if (pageNumber > numberOfPages - 5) {
            setEnd(numberOfPages + 10);
        } else {
            setEnd(pageNumber + 5);
        }



        // setEnd((pageNumber > numberOfPages-5? numberOfPages + 5 :pageNumber + 5))
    }, [pageNumber, numberOfPages, firstFunction, limit, dispatch, search, setStart, setEnd]);


    // function to handle show entries select box
    const handleChange = (e) => {
        setLimit(e.target.value);
    };

    // console.log((pageNumber < 5)? 0:pageNumber - 5, (pageNumber < numberOfPages? pageNumber + 5 :numberOfPages))
    const goToPrev = (gotoPage) => {
        setPageNumber(Math.max(0, pageNumber - 1));
    };
    const goToNext = (gotoPage) => {
        setPageNumber(Math.min(numberOfPages, pageNumber + 1));
    };

    // Search function
    useEffect(() => {
        const runSearchFunction = () => {
            if (!search.trim()) {
                dispatch(firstFunction(pageNumber, limit, ""));
            } else {
                dispatch(firstFunction(pageNumber, limit, search));
            }
        };

        let debouncer = setTimeout(() => {
            runSearchFunction();
        }, 100);

        return () => {
            clearTimeout(debouncer);
        };
    }, [search]);

    return {
        loginUser,
        data,
        start,
        setStart,
        end,
        setEnd,
        pagination,
        pages,
        count,
        total,
        pageNumber,
        handleChange,
        goToNext,
        goToPrev,
        limit,
        setLimit,
        search,
        loading,
        setSearch,
        setPageNumber,
    };
};

export default Pagination;
