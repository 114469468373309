import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        // title: {
        //     display: true,
        //     text: 'Chart.js Bar Chart',
        // },
    },
};


const DashboardPersonGenderBarChart = ({counts,}) => {
    let labels = counts.map(county=> county.county)
    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: 'Male',
                data: counts.map(personInfo => personInfo.totalMale),
                borderColor: 'rgb(207,7,40)',
                backgroundColor: 'rgba(235,53,86,0.5)',
            },
            {
                fill: true,
                label: 'Female',
                data: counts.map(personInfo => personInfo.totalFemale),
                borderColor: 'rgb(237,211,11)',
                backgroundColor: 'rgba(252,225,13,0.5)',
            },
        ],
    };
    return <Bar options={options} data={data} />;
}

export default DashboardPersonGenderBarChart