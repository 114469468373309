import ContentHeader from '../../components/layouts/ContentHeader';
import { getVehicle } from '../../redux/actions/regVehiclesAction';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../components/Spinner';
import { useParams } from 'react-router-dom';
import { Fragment, useEffect } from 'react';
// import { toast } from 'react-toastify';
import moment from 'moment';
import Swal from 'sweetalert2'

function RegisterVehicleDetails() {
    const { vehicle, loading, error } = useSelector(state => state.regVehicleReducer);
    const dispatch = useDispatch();
    const params = useParams();

    console.log(vehicle);

    useEffect(() => {
        dispatch(getVehicle(params.id));
    }, [dispatch, params]);

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        // toast.error(error);

        Swal.fire({
            title: error,
            text: error,
            icon: 'error',
            confirmButtonText: 'Okay'
        })
    }

    return vehicle
        ? <Fragment>
            <ContentHeader
                title="Accident Crash DB v1"
                content="Register Vehicle Details"
            />

            {/* <!-- Main content --> */}
            <section className="content">
                <div className="container-fluid">
                    {/* <!-- ACCIDENT INFORMATION --> */}
                    <div className="card card-default">
                        <div className="card-header">
                            <h3 className="card-title">
                                <b className="text-danger text-lg">{vehicle.plateNumber}</b> VEHICLE
                                INFORMATION
                            </h3>

                            <div className="card-tools">
                                <button
                                    type="button"
                                    className="btn btn-tool"
                                    data-card-widget="collapse"
                                >
                                    <i className="fas fa-minus" />
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-tool"
                                    data-card-widget="remove"
                                >
                                    <i className="fas fa-times" />
                                </button>
                            </div>
                        </div>
                        {/* <!-- /.card-header --> */}
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6 col-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Owner Name</label>
                                        <input
                                            type="text"
                                            name=""
                                            value={vehicle.ownerName}
                                            className="form-control"
                                            placeholder=""
                                        // disabled="disabled"
                                        />
                                    </div>
                                    {/* <!-- /.form-group --> */}
                                </div>
                                {/* <!-- /.col --> */}
                                <div className="col-md-6 col-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Owner Address</label>
                                        <input
                                            type="text"
                                            name=""
                                            value={vehicle.ownersAddress}
                                            className="form-control"
                                            placeholder=""
                                        // disabled="disabled"
                                        />
                                    </div>
                                    {/* <!-- /.form-group --> */}
                                </div>
                                {/* <!-- /.col --> */}
                                <div className="col-md-6 col-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Owner Address</label>
                                        <input
                                            type="text"
                                            name=""
                                            value={vehicle.ownersAddress}
                                            className="form-control"
                                            placeholder=""
                                        // disabled="disabled"
                                        />
                                    </div>
                                    {/* <!-- /.form-group --> */}
                                </div>
                                {/* <!-- /.col --> */}
                                <div className="col-md-6 col-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Contact Number</label>
                                        <input
                                            type="phone"
                                            name=""
                                            value={vehicle.cellNumber}
                                            className="form-control"
                                            placeholder=""
                                        // disabled="disabled"
                                        />
                                    </div>
                                    {/* <!-- /.form-group --> */}
                                </div>
                                {/* <!-- /.col --> */}
                                {/* `/register-vehicles/${vehicle._id}` */}
                                <div className="col-md-6 col-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Online/Direct Record</label>
                                        <input
                                            type="text"
                                            name=""
                                            value={vehicle.DirectRecord}
                                            className="form-control"
                                            placeholder=""
                                        // disabled="disabled"
                                        />
                                    </div>
                                    {/* <!-- /.form-group --> */}
                                </div>
                                {/* <!-- /.col --> */}
                                <div className="col-md-6 col-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Plate Number</label>
                                        <input
                                            type="text"
                                            name=""
                                            value={vehicle.plateNumber}
                                            className="form-control"
                                            placeholder=""
                                        // disabled="disabled"
                                        />
                                    </div>
                                    {/* <!-- /.form-group --> */}
                                </div>
                                {/* <!-- /.col --> */}
                                <div className="col-md-6 col-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Amount</label>
                                        <input
                                            type="text"
                                            name=""
                                            // value={'$' + vehicle.amount}
                                            value={'$' + vehicle.amount}
                                            className="form-control"
                                            placeholder="Traffic Inspector"
                                        // disabled="disabled"
                                        />
                                    </div>
                                    {/* <!-- /.form-group --> */}
                                </div>
                                {/* <!-- /.col --> */}
                                <div className="col-md-6 col-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">District</label>
                                        <input
                                            type="text"
                                            name=""
                                            value={vehicle.district}
                                            className="form-control"
                                            placeholder="Monrovia"
                                        // disabled="disabled"
                                        />
                                    </div>
                                    {/* <!-- /.form-group --> */}
                                </div>
                                {/* <!-- /.col --> */}
                                <div className="col-md-6 col-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">County</label>
                                        <input
                                            className="form-control select2"
                                            name=""
                                            type='text'
                                            value={vehicle.county}
                                            style={{ width: '100%' }}
                                        // disabled="disabled"
                                        />
                                    </div>
                                    {/* <!-- /.form-group --> */}
                                </div>
                                {/* <!-- /.col --> */}
                                <div className="col-md-6 col-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Office</label>
                                        <input
                                            type="text"
                                            name=""
                                            value={vehicle.office}
                                            className="form-control"
                                            placeholder=""
                                        // disabled="disabled"
                                        />
                                    </div>
                                    {/* <!-- /.form-group --> */}
                                </div>
                                {/* <!-- /.col --> */}
                                <div className="col-md-6 col-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Type</label>
                                        <input
                                            type="text"
                                            name=""
                                            value={vehicle.type}
                                            className="form-control"
                                            placeholder=""
                                        // disabled="disabled"
                                        />
                                    </div>
                                    {/* <!-- /.form-group --> */}
                                </div>
                                {/* <!-- /.col --> */}
                                <div className="col-md-6 col-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Color</label>
                                        <input
                                            type="text"
                                            name=""
                                            value={vehicle.color}
                                            className="form-control"
                                            placeholder=""
                                        // disabled="disabled"
                                        />
                                    </div>
                                    {/* <!-- /.form-group --> */}
                                </div>
                                {/* <!-- /.col --> */}
                                <div className="col-md-6 col-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Make</label>
                                        <input
                                            type="text"
                                            name=""
                                            value={vehicle.make}
                                            className="form-control"
                                            placeholder=""
                                        // disabled="disabled"
                                        />
                                    </div>
                                    {/* <!-- /.form-group --> */}
                                </div>
                                {/* <!-- /.col --> */}
                                <div className="col-md-6 col-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Model</label>
                                        <input
                                            type="text"
                                            name=""
                                            value={vehicle.model}
                                            className="form-control"
                                            placeholder=""
                                        // disabled="disabled"
                                        />
                                    </div>
                                    {/* <!-- /.form-group --> */}
                                </div>
                                {/* <!-- /.col --> */}
                                <div className="col-md-6 col-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Year</label>
                                        <input
                                            type="text"
                                            name=""
                                            value={vehicle.year}
                                            className="form-control"
                                            placeholder=""
                                        // disabled="disabled"
                                        />
                                    </div>
                                    {/* <!-- /.form-group --> */}
                                </div>
                                {/* <!-- /.col --> */}
                                <div className="col-md-6 col-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Payment Date</label>
                                        <input
                                            type="text"
                                            name=""
                                            value={vehicle.paymentDate ? moment(vehicle.paymentDate).format('MMMM D, YYYY') : ''}
                                            className="form-control"
                                            placeholder=""
                                        // disabled="disabled"
                                        />
                                    </div>
                                    {/* <!-- /.form-group --> */}
                                </div>
                                {/* <!-- /.col --> */}
                                <div className="col-md-6 col-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Register Date</label>
                                        <input
                                            type="text"
                                            name=""
                                            value={vehicle.regDate ? moment(vehicle.regDate).format('MMMM D, YYYY') : ''}
                                            className="form-control"
                                            placeholder=""
                                        // disabled="disabled"
                                        />
                                    </div>
                                    {/* <!-- /.form-group --> */}
                                </div>
                                {/* <!-- /.col --> */}
                                <div className="col-md-6 col-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Tax Period Date</label>
                                        <input
                                            type="text"
                                            name=""
                                            value={vehicle.taxPeriod}
                                            className="form-control"
                                            placeholder=""
                                        // disabled="disabled"
                                        />
                                    </div>
                                    {/* <!-- /.form-group --> */}
                                </div>
                                {/* <!-- /.col --> */}
                                <div className="col-md-6 col-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Seating Capacity</label>
                                        <input
                                            type="text"
                                            name=""
                                            value={vehicle.seatingCapacity}
                                            className="form-control"
                                            placeholder=""
                                        // disabled="disabled"
                                        />
                                    </div>
                                    {/* <!-- /.form-group --> */}
                                </div>
                                {/* <!-- /.col --> */}
                                <div className="col-md-6 col-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="">Weight</label>
                                        <input
                                            type="text"
                                            name=""
                                            value={vehicle.weight}
                                            className="form-control"
                                            placeholder=""
                                        // disabled="disabled"
                                        />
                                    </div>
                                    {/* <!-- /.form-group --> */}
                                </div>
                            </div>
                            {/* <!-- /.row --> */}
                        </div>
                        {/* <!-- /.card-body --> */}
                    </div>
                    {/* <!-- /.card --> */}
                </div>
                {/* <!-- /.container-fluid --> */}
            </section>
            {/* <!-- /.content --> */}
        </Fragment>
        : <Spinner />;
}

export default RegisterVehicleDetails;
