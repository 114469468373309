import React, { useEffect, useState } from "react";
import ContentHeader from "../../components/layouts/ContentHeader";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getLicenses } from "../../redux/actions/licenseAction";
import moment from "moment";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import "jspdf-autotable";
import GeneratePdf from "../../components/GeneratePdf";
const AllLicenses = () => {

  //select option for entries
  const selectOption = [10, 25, 50, 100];

  const {
    loginUser,
    loading,
    setPageNumber,
    data,
    count,
    total,
    start,
    end,
    pagination,
    pages,
    pageNumber,
    handleChange,
    goToNext,
    goToPrev,
    limit,
    search,
    setSearch,
  } = Pagination(
    (state) => state.licenseReducer.licenses,
    (state) => state.licenseReducer,
    (state) => state.authReducer.loggedInUser,
    getLicenses,
    useState,
    useDispatch,
  );

  return (
    <>
      <ContentHeader title="All licenses" content="All licenses" />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className='card-header'>
              <h3 className='card-title '>Driver Licenses</h3>
              <div className="text-right">
                  {loading ? (
                      <div>Loading...</div>
                  ) : (
                  <>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button btn-sm btn-success"
                        table="example1"
                        filename="licenses"
                        sheet="licenses"
                        // buttonText="Export XLS"
                        buttonText={<i className="far fa-file-excel"> Export XLS</i>}
                    /> &nbsp;
                    {/* <button onClick={() => exportPDF(eventLogs)} className="download-table-xls-button btn-sm btn-danger"><i className="fa fa-file-pdf" /> Export PDF</button> */}

                    {/* Export PDF function */}
                    <button onClick={() => {
                      GeneratePdf(
                          data.map(license => [license.licenseNumber, license.fullName, license.Category, license.office, moment(license.issued).format("MMM-DD-YYYY"), moment(license.expire).format("MMM-DD-YYYY")]),
                          [["License Number", "Name", "Category", "Office", "Issue Date", "Expire Date"]],
                          'Driver Licenses',
                          'licenses.pdf'
                      )
                    }} className="download-table-xls-button btn-sm btn-danger">
                      <i className="fa fa-file-pdf" /> Export PDF
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="row mb-1">
                <div className="col-sm-12 col-md-6 col-12 d-inline-block">
                  <div className="dataTables_length d-inline">
                    <span>Show</span>
                    <select name="" defaultValue={limit} onChange={(e) => handleChange(e)} className="custom-select custom-select-sm form-control form-control-sm mx-2"
                            style={{ width: '70px' }}>
                      {selectOption.map((option, index) => (
                          <option key={index} value={option}>{option}</option>
                      ))}
                    </select>
                    <span>entries</span>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-12 d-inline-block" style={{ textAlign: 'right' }}>
                  <div id="example1_filter" className="dataTables_filter float-right">
                    <label style={{ fontWeight: 'normal', whiteSpace: 'nowrap', textAlign: 'left' }}>Search:<input
                        type="search" className="form-control form-control-sm" aria-label='search' value={search} onChange={(e) => setSearch(e.target.value)}
                        style={{ marginLeft: '0.5em', display: 'inline-block', width: 'auto' }} />
                    </label>
                  </div>
                </div>
              </div>
              {loading ? (
                  <Loader />
              ) : (
                  <>
                  <div>
                    <table
                      id="example1"
                      className="table table-responsive-md table-bordered table-striped"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          {/*<th>N0.</th>*/}
                          <th>License</th>
                          <th>Full Name</th>
                          <th>Category</th>
                          <th>Office</th>
                          <th>Issue Date</th>
                          <th>Expire Date</th>
                          <th>
                            <i className="fas fa-ellipsis-v-alt" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.map((license) => (
                            <tr key={license._id}>
                              {/*<td>{index + 1}</td>*/}
                              <td>
                                <Link to={`/licenses/${license._id}`}>
                                  {license.licenseNumber}
                                </Link>
                              </td>
                              <td>{license.fullName}</td>
                              <td>{license.Category}</td>
                              <td>{license.office}</td>
                              <td>
                                {moment(license.issued).format("MMM-DD-YYYY")}
                              </td>
                              <td>
                                {moment(license.expire).format("MMM-DD-YYYY")}
                              </td>
                              <td>
                                <Link to={`/licenses/${license._id}`}>
                                  <i className="far fa-eye" />
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <nav aria-label="">
                      <ul className="pagination">
                        <li className="page-item">
                          <button
                            className={
                              "page-link btn" +
                              (!pagination.prev ? "btn-disable" : null)
                            }
                            onClick={() => {
                              if (pagination.prev) {
                                goToPrev(pagination.prev);
                              }
                            }}
                          >
                            Previous
                          </button>
                        </li>
                        {pages.slice(start, end).map((pageIndex) => (
                          <li className="page-item" key={pageIndex + 1}>
                            <a
                              className={
                                "page-link " +
                                (pageNumber == pageIndex + 1
                                  ? "bg-primary text-primary"
                                  : null)
                              }
                              key={pageIndex + 1}
                              onClick={() => setPageNumber(pageIndex + 1)}
                            >
                              {pageIndex + 1}{" "}
                            </a>
                          </li>
                        ))}
                        <li className="page-item">
                          <button
                            className={
                              "page-link btn" +
                              (!pagination.next ? "btn-disable" : null)
                            }
                            onClick={() => {
                              if (pagination.next) {
                                goToNext(pagination.next.page);
                              }
                            }}
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </nav>
                    {"Showing " + (count*pageNumber-count+1) + " to " + count*pageNumber  + " of " + total}
                  </div>
                {/*)}*/}
                <br />
                {/*{ pages.map((pageIndex) => (<button key={pageIndex} onClick={() => setPageNumber(pageIndex + 1)}>{ pageIndex + 1} </button>)) }*/}

                  </>
              )}
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllLicenses;
