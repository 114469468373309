import moment from 'moment';
import { toast } from 'react-toastify'
import { Fragment, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../components/Spinner'
import { getAccident } from '../../redux/actions/accidentsAction'
import ContentHeader from '../../components/layouts/ContentHeader'

function AccidentDetails() {
    const { accident, loading, error } = useSelector(state => state.accidentReducer);
    const dispatch = useDispatch()
    const params = useParams()

    // console.log(accident)

    useEffect(() => {
        dispatch(getAccident(params.id))
    }, [dispatch, params]);

    if (loading) {
        return <Spinner />
    }

    if (error) {
        toast.error(error)
    }

    return (
        accident ?
            <Fragment>
                <ContentHeader title='Accident Crash DB v1' content='Accident Details' />

                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="card">
                            <div className="card-header p-2">
                                <ul className="nav nav-pills">
                                    <li className="nav-item">
                                        <a className="nav-link active" href="#crash" data-toggle="tab">
                                            Crash
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#vehicle" data-toggle="tab">
                                            Vehicle(s)
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#persons" data-toggle="tab">
                                            Persons
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#road" data-toggle="tab">
                                            Road
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            {/* /.card-header */}
                            <div className="card-body">
                                <div className="tab-content">
                                    <div className="active tab-pane" id="crash">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 order-1 order-md-2">
                                                <h3 className="text-primary"><i className="fas fa-paint-brush"></i> Overview</h3>
                                                <p className="text-muted">Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terr.</p>
                                                <br />
                                                <div className="card card-primary card-outline">
                                                    <div className="card-body">

                                                        <div className="text-muted">
                                                            <div className="row">
                                                                <div className="col-12 col-md-12 col-lg-12 order-1 order-md-2">
                                                                    <div className="text-muted">
                                                                        <div className="row justify-content-center">
                                                                            <div className="col-4 col-md-4 col-lg-4 order-1 order-md-2">
                                                                                <h3 className="text-md text-muted mb-3">CITY
                                                                                    <b className="d-block text-muted">{accident && accident.city}</b>
                                                                                </h3>
                                                                                <h3 className="text-md text-muted mb-3">COMMUNITY
                                                                                    <b className="d-block text-muted">{accident && accident.community}</b>
                                                                                </h3>
                                                                                <h3 className="text-md text-muted mb-3">COUNTY
                                                                                    <b className="d-block text-muted">{accident && accident.county}</b>
                                                                                </h3>
                                                                            </div>

                                                                            <div className="col-4 col-md-4 col-lg-4 order-1 order-md-2">
                                                                                <h3 className="text-md text-muted mb-3">ROAD
                                                                                    <b className="d-block text-muted">{accident && accident.roadLocation}</b>
                                                                                </h3>
                                                                                <h3 className="text-md text-muted mb-3">VISIBILITY
                                                                                    <b className="d-block text-muted">{accident && accident.visibility}</b>
                                                                                </h3>
                                                                                <h3 className="text-md text-muted mb-3">WEATHER CONDITION
                                                                                    <b className="d-block text-muted">{accident && accident.weatherCondition}</b>
                                                                                </h3>
                                                                            </div>

                                                                            <div className="col-4 col-md-4 col-lg-4 order-1 order-md-2">
                                                                                <h5 className="text-primary">Crash Types</h5>

                                                                                <ul className="list-unstyled mb-3">
                                                                                    {accident.crash && accident.crash.map(crash => (
                                                                                        <li key={crash._id}>
                                                                                            <b className="d-block text-muted"><i className="fas fa-user-injured"></i> {crash.crashType}</b>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h5 className="mt-4 text-primary">GALLERY</h5>
                                        <div className="row mt-4">
                                            <div className="col-sm-4">
                                                <div className="position-relative">
                                                    <img src="../../assets/img/bg-img.jpeg" alt="Photo1" className="img-fluid" />
                                                    <div className="ribbon-wrapper ribbon-lg">
                                                        <div className="ribbon bg-success text-lg">
                                                            Fair
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="position-relative">
                                                    <img src="../../assets/img/bg-img.jpeg" alt="Photo1" className="img-fluid" />
                                                    <div className="ribbon-wrapper ribbon-xl">
                                                        <div className="ribbon bg-warning text-lg">
                                                            Bad
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="position-relative" style={{ minHeight: "180px" }}>
                                                    <img src="../../assets/img/bg-img.jpeg" alt="Photo1" className="img-fluid" />
                                                    <div className="ribbon-wrapper ribbon-xl">
                                                        <div className="ribbon bg-danger text-lg">
                                                            Damage
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.tab-pane */}
                                    <div className="tab-pane" id="vehicle">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 order-1 order-md-2">
                                                <h3 className="text-primary"><i className="fas fa-paint-brush"></i> Overview</h3>
                                                <p className="text-muted">Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terr.</p>
                                                <br />
                                                <div className="text-muted">
                                                    <div className="row">
                                                        {accident.vehicle && accident.vehicle.map(car => (
                                                            <div className="col-4 col-md-4 col-lg-4 order-1 order-md-2" key={car._id}>
                                                                <div className="card card-primary card-outline">
                                                                    <div className="card-body">
                                                                        <h3 className="text-md text-primary mb-3">VEHICLE STATUS
                                                                            <b className="d-block text-muted">{accident && car.bodyType}</b>
                                                                        </h3>
                                                                        <h3 className="text-md text-primary mb-3">BREAK LIGHT
                                                                            <b className="d-block text-muted">{accident && car.brakeLight}</b>
                                                                        </h3>
                                                                        <h3 className="text-md text-primary mb-3">COLOR
                                                                            <b className="d-block text-muted">{accident && car.color}</b>
                                                                        </h3>
                                                                        <h3 className="text-md text-primary mb-3">HEAD LIGHT
                                                                            <b className="d-block text-muted">{accident && car.headLight}</b>
                                                                        </h3>
                                                                        <h3 className="text-md text-primary mb-3">VEHICLE MODEL
                                                                            <b className="d-block text-muted">{accident && car.model}</b>
                                                                        </h3>
                                                                        <h3 className="text-md text-primary mb-3">SIGNAL LIGHT
                                                                            <b className="d-block text-muted">{accident && car.signaLight}</b>
                                                                        </h3>
                                                                        <h3 className="text-md text-primary mb-3">VEHICLE TYPE
                                                                            <b className="d-block text-muted">{accident && car.type}</b>
                                                                        </h3>
                                                                        <h3 className="text-md text-primary mb-3">YEAR
                                                                            <b className="d-block text-muted">{moment(accident && car.year).format('YYYY')}</b>
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <h5 className="mt-4 text-primary">GALLERY</h5>
                                        <div className="row mt-4">
                                            <div className="col-sm-4">
                                                <div className="position-relative">
                                                    <img src="../../assets/img/bg-img.jpeg" alt="Photo2" className="img-fluid" />
                                                    <div className="ribbon-wrapper ribbon-lg">
                                                        <div className="ribbon bg-success text-lg">
                                                            Fair
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="position-relative">
                                                    <img src="../../assets/img/bg-img.jpeg" alt="Photo2" className="img-fluid" />
                                                    <div className="ribbon-wrapper ribbon-xl">
                                                        <div className="ribbon bg-warning text-lg">
                                                            Bad
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="position-relative" style={{ minHeight: "180px" }}>
                                                    <img src="../../assets/img/bg-img.jpeg" alt="Photo2" className="img-fluid" />
                                                    <div className="ribbon-wrapper ribbon-xl">
                                                        <div className="ribbon bg-danger text-lg">
                                                            Damage
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.tab-pane */}
                                    <div className="tab-pane" id="persons">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 order-1 order-md-2">
                                                <h3 className="text-primary"><i className="fas fa-paint-brush"></i> Overview</h3>
                                                <p className="text-muted">Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terr.</p>
                                                <br />
                                                <div className="text-muted">
                                                    <div className="row">
                                                        {accident.person && accident.person.map(person => (
                                                            <div className="col-4 col-md-4 col-lg-4 order-1 order-md-2" key={person._id}>
                                                                <div className="card card-primary card-outline">
                                                                    <div className="card-body">
                                                                        <h3 className="text-md text-primary mb-3">PERSON ID
                                                                            <b className="d-block text-muted">{accident && person.PersonsID}</b>
                                                                        </h3>
                                                                        <h3 className="text-md text-primary mb-3">PERSON FINAL DIAGNOSIS
                                                                            <b className="d-block text-muted">{accident && person.finalDiagnosis}</b>
                                                                        </h3>
                                                                        <h3 className="text-md text-primary mb-3">PERSON GENDER
                                                                            <b className="d-block text-muted">{accident && person.gender}</b>
                                                                        </h3>
                                                                        <h3 className="text-md text-primary mb-3">PERSON TYPE
                                                                            <b className="d-block text-muted">{accident && person.type}</b>
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <h5 className="mt-4 text-primary">GALLERY</h5>
                                        <div className="row mt-4">
                                            <div className="col-sm-4">
                                                <div className="position-relative">
                                                    <img src="../../assets/img/bg-img.jpeg" alt="Photo3" className="img-fluid" />
                                                    <div className="ribbon-wrapper ribbon-lg">
                                                        <div className="ribbon bg-success text-lg">
                                                            Fair
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="position-relative">
                                                    <img src="../../assets/img/bg-img.jpeg" alt="Photo3" className="img-fluid" />
                                                    <div className="ribbon-wrapper ribbon-xl">
                                                        <div className="ribbon bg-warning text-lg">
                                                            Bad
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="position-relative" style={{ minHeight: "180px" }}>
                                                    <img src="../../assets/img/bg-img.jpeg" alt="Photo3" className="img-fluid" />
                                                    <div className="ribbon-wrapper ribbon-xl">
                                                        <div className="ribbon bg-danger text-lg">
                                                            Damage
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.tab-pane */}
                                    <div className="tab-pane" id="road">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 order-1 order-md-2">
                                                <h3 className="text-primary"><i className="fas fa-paint-brush"></i> Overview</h3>
                                                <p className="text-muted">Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terr.</p>
                                                <br />
                                                <div className="text-muted">
                                                    <div className="row">
                                                        {accident.road && accident.road.map(road => (
                                                            <div className="col-4 col-md-4 col-lg-4 order-1 order-md-2" key={road._id}>
                                                                <div className="card card-primary card-outline">
                                                                    <div className="card-body">
                                                                        <h3 className="text-md text-primary mb-3">TRAFFIC CONTROL
                                                                            <b className="d-block text-muted">{accident && road.Trafficontrol}</b>
                                                                        </h3>
                                                                        <h3 className="text-md text-primary mb-3">LEGAL SPEED LIMIT
                                                                            <b className="d-block text-muted">{accident && road.legalSpeedLimit}</b>
                                                                        </h3>
                                                                        <h3 className="text-md text-primary mb-3">OBSTACLE
                                                                            <b className="d-block text-muted">{accident && road.roadObstacle}</b>
                                                                        </h3>
                                                                        <h3 className="text-md text-primary mb-3">SEGMENT GRADE
                                                                            <b className="d-block text-muted">{accident && road.roadSegmentGrade}</b>
                                                                        </h3>
                                                                        <h3 className="text-md text-primary mb-3">ROAD SEGMENT
                                                                            <b className="d-block text-muted">{accident && road.roadSigmen}</b>
                                                                        </h3>
                                                                        <h3 className="text-md text-primary mb-3">SURFACE CONDITION
                                                                            <b className="d-block text-muted">{accident && road.surfaceCondition}</b>
                                                                        </h3>
                                                                        <h3 className="text-md text-primary mb-3">ROAD TYPE
                                                                            <b className="d-block text-muted">{accident && road.type}</b>
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <h5 className="mt-4 text-primary">GALLERY</h5>
                                        <div className="row mt-4">
                                            <div className="col-sm-4">
                                                <div className="position-relative">
                                                    <img src="../../assets/img/bg-img.jpeg" alt="Photo4" className="img-fluid" />
                                                    <div className="ribbon-wrapper ribbon-lg">
                                                        <div className="ribbon bg-success text-lg">
                                                            Fair
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="position-relative">
                                                    <img src="../../assets/img/bg-img.jpeg" alt="Photo4" className="img-fluid" />
                                                    <div className="ribbon-wrapper ribbon-xl">
                                                        <div className="ribbon bg-warning text-lg">
                                                            Bad
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="position-relative" style={{ minHeight: "180px" }}>
                                                    <img src="../../assets/img/bg-img.jpeg" alt="Photo4" className="img-fluid" />
                                                    <div className="ribbon-wrapper ribbon-xl">
                                                        <div className="ribbon bg-danger text-lg">
                                                            Damage
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.tab-pane */}
                                </div>
                                {/* /.tab-content */}
                            </div>
                            {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </div>
                    {/* /.col */}
                </div>
                {/* /.row */}
            </Fragment>
            : <Spinner />
    )
}

export default AccidentDetails
