import React, { Fragment } from 'react'
import { Outlet } from 'react-router-dom'

function ContentWrapper() {

    return (
        <Fragment>
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">

                {/* Content Header (Page header) */}
                {/* <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Accident Crash v1</h1>
                            </div>
                            // /.col
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Dashboard</li>
                                </ol>
                            </div>
                            // /.col
                        </div>
                        // /.row
                    </div>
                    // /.container-fluid
                </div> */}
                {/* /.content-header */}

                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <Outlet />
                    </div>
                    {/*/. container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
        </Fragment>
    )
}

export default ContentWrapper