import { getUserToken, prodAxios } from '../../utils/sysConfig'
// import { toast } from 'react-toastify';
import * as types from '../types'
import Swal from 'sweetalert2'

// GET ALL ACCIDENTS
export const getLoggers = (page, entry, search) => async dispatch => {

    try {
        setLoading(dispatch)

        await prodAxios.get(`/api/v1/loggers?page=${page}&limit=${entry}&search=${search}`, await getUserToken())
            .then((res) => {
                // console.log(res)

                dispatch({
                    type: types.GET_ALL_LOGS,
                    payload: res.data.data,
                    pagination: res.data.pagination,
                    numberOfPages: res.data.numOfPages,
                    total: res.data.totalLogger,
                    count: res.data.count,
                })
            })
            .catch((error) => {
                console.log(error)

                Swal.fire({
                    title: error.code,
                    text: error.message,
                    icon: 'error',
                    footer: '<a href="">Please check your internet connection</a>',
                    confirmButtonText: 'Ok',
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    }
                })

                if (error.response === undefined) {
                    setError(dispatch, error.message)
                } else {
                    setError(dispatch, error.response.data.error)
                }
            })
    } catch (error) {
        console.log(error)
        setError(dispatch, error.message)
    }
}

// GET ONE ACCIDENT ONLY
// export const getLogger = (id) => async dispatch => {

//     try {
//         setLoading(dispatch)

//         await prodAxios.get('/api/v1/loggers/' + id, await getUserToken())
//             .then((res) => {
//                 console.log(res.data.data)

//                 dispatch({
//                     type: types.GET_ONE_LOG,
//                     payload: res.data.data
//                 })
//             })
//             .catch((error) => {

//                 Swal.fire({
//                     title: error.code,
//                     text: error.message,
//                     icon: 'error',
//                     footer: '<a href="">Please check your internet connection</a>',
//                     confirmButtonText: 'Ok',
//                     showClass: {
//                         popup: 'animate__animated animate__fadeInDown'
//                     },
//                     hideClass: {
//                         popup: 'animate__animated animate__fadeOutUp'
//                     }
//                 })

//                 if (error.response === undefined) {
//                     setError(dispatch, error.message)
//                 } else {
//                     setError(dispatch, error.response.data.error)
//                 }
//             })
//     } catch (error) {
//         if (error.response === undefined) {
//             setError(dispatch, error.message)
//         } else {
//             setError(dispatch, error.response.data.error)
//         }
//     }
// }

// Constant functions
export const reset = () => async dispatch => {
    dispatch({
        type: types.RESET
    })
}

const setLoading = (dispatch) => {
    dispatch({
        type: types.SET_LOADING,
    });
}

const setError = (dispatch, error) => {
    dispatch({
        type: types.SET_ERROR,
        payload: error
    });

}
