import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        // title: {
        //     display: true,
        //     text: 'Chart.js Bar Chart',
        // },
    },
};


const DashboardPersonConditionBarChart = ({counts}) => {
    let labels = counts.map(county=> county.county,)

    const data = {
        labels,
        datasets: [
                {
                    fill: true,
                    label: "Fatalities",
                    data: counts.map(personInfo => personInfo.totalFatalities),
                    borderColor: 'rgb(207,7,40)',
                    backgroundColor: 'rgba(235,53,86,0.5)',
                },
                {
                    fill: true,
                    label: "Injuries",
                    data: counts.map(personInfo => personInfo.totalInjuries),
                    borderColor: 'rgb(237,211,11)',
                    backgroundColor: 'rgba(252,225,13,0.5)',
                },
                {
                    fill: true,
                    label: "Normal",
                    data: counts.map(personInfo => personInfo.totalNormal),
                    borderColor: 'rgb(141,244,7)',
                    backgroundColor: 'rgba(150,244,6,0.5)',
                }
        ],
    };
    return <Bar options={options} data={data} />;
}

export default DashboardPersonConditionBarChart