import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {resetPassword, updateUserPwd} from "../redux/actions/authAction";
import {toast} from "react-toastify";
import PasswordChecklist from "react-password-checklist";
import {reset} from "../redux/actions/usersAction";
const ChangePwdModal = ({userId, userName, modalTitle}) =>{

    const dispatch = useDispatch();
    // useref is use to hide the modal after the user update their password
    const closeRef = useRef();
    // password variable holds {currentPassword, newPassword as pwd, and Password Confirm as pwdCon}
    const [password, setPassword] = useState({ curPwd:'', pwd: '', pwdCon: ''});
    // password error is use to stop the user from updating the password if the do not meet the password requirement
    const [pwdError, setPwdError] = useState(true);
    // load is use to set the loading icon on the submit button if the user press the update password button
    const [load, setLoad] = useState(false);

    // destructured password and password confirm from user state
    const { curPwd, pwd, pwdCon } = password;

    //get resetPwd and error from authReducer
    const { resetPwd, error} = useSelector(state => state.authReducer)

    // function that is call when the user press the update password button
    const handleUpdatePwd = (closeRef, e) => {
        e.preventDefault()
        // check if the password error is false and submit the form else display toast error
        if (!pwdError){
            setLoad(true)
            const data = { currentPassword:curPwd, newPassword:pwd };
            dispatch(updateUserPwd(userId, data));
            // after updating the password wait for 1 second and hide the modal
            setTimeout(()=>{
                console.log(closeRef.current.click())
            }, 1000)
        }else{
            setLoad(false)
            toast.info("Password must meet all rules before submitted")
        }
    }

    // reset all input filed
    const resetPasswordInput = () =>{
        setPassword((prevState) =>({
            ...prevState,
            curPwd: "",
            pwdCon: ""
        }))
    }
    // check if the password have been updated or the was an error updating it and reset all local state form and auth reducer
    useEffect(() => {
        if (resetPwd){
            setLoad(false)
            resetPasswordInput();
            dispatch(reset())
            // navigate('/profile')
        }
    }, [resetPwd]);

    // check if error and reset all local state form and auth reducer
    useEffect(() => {
        if (error){
            toast.error(error)
            setLoad(false)
            resetPasswordInput()
            dispatch(reset())
        }
    }, [error]);

    // handle on change to handle
    const handleOnChange = (e) =>{
        setPassword((prevState) =>({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    return(
        <div className="modal fade" id="changePwd">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title"> {modalTitle}</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                {/* officer photo input field */}
                                <label>Current Password</label>
                                <input
                                    className="form-control multisteps-form__input"
                                    type="password"
                                    name="curPwd"
                                    value={curPwd}
                                    onChange={handleOnChange}
                                />
                            </div>
                            <div className="col-12">
                                {/* officer photo input field */}
                                <label>New Password</label>
                                <input
                                    className="form-control multisteps-form__input"
                                    type="password"
                                    name="pwd"
                                    value={pwd}
                                    onChange={handleOnChange}
                                />
                            </div>
                            <div className="col-12">
                                {/* officer photo input field */}
                                <label>Confirm Password</label>
                                <input
                                    className="form-control multisteps-form__input"
                                    type="password"
                                    name="pwdCon"
                                    value={pwdCon}
                                    onChange={handleOnChange}
                                />
                            </div>
                            <PasswordChecklist
                                className="ml-2 mt-2"
                                rules={["minLength","specialChar","number","capital","match"]}
                                minLength={5}
                                value={pwd}
                                valueAgain={pwdCon}
                                onChange={(isValid) => {
                                    if (isValid){
                                        setPwdError(false)
                                        console.log('valid');
                                    }else{
                                        setPwdError(true)
                                        console.log('not valid')
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="modal-footer justify-content-right">
                        <button type="button" ref={closeRef} className="btn btn-primary" data-dismiss="modal">Cancel</button>
                        {load?
                            <p className="btn btn-primary"><i className="fa fa-spinner" /> Loading </p>
                            :
                            <button type="button" onClick={(e) => handleUpdatePwd(closeRef, e)} className="btn btn-danger">Update Password</button>
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}


export default ChangePwdModal




