/* global google */
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect, useMemo } from 'react'
import { GoogleMap, useLoadScript, InfoWindow, Marker } from '@react-google-maps/api';
import { getAccidents } from '../redux/actions/accidentsAction';
import { Link } from 'react-router-dom';

const LiveMap = ({mapstyle, zoom}) => {

  const { accidents} = useSelector((state) => state.accidentReducer);
  const dispatch = useDispatch();
  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();

  useEffect(() => {
    dispatch(getAccidents());
  }, [dispatch]);


  const center = useMemo(() => ({ lat: 6.503634, lng: -9.386509 }), []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAySfcgE2ybHWsBCP1bnnjHHU-olDYIzYQ"
  });

  const onMapLoad = (map) => {
    setMapRef(map);
    const bounds = new google.maps.LatLngBounds();
    accidents.coordinates?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    // map.fitBounds(bounds);
  };


  const handleMarkerClick = (id, title, lat, lng) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({id, title, lat, lng });
    setIsOpen(true);
  };
 
  
  return (
    <> 
    {!isLoaded ?  <h4 className='display-4'> loading...</h4> : 
    (
      <GoogleMap 
        mapContainerStyle={mapstyle}
        center={center} 
        zoom={zoom}
        onLoad={onMapLoad}
        onClick={() => setIsOpen(false)}
      >
      { /* Child components, such as markers, info windows, etc. */ }
      {accidents.map(({ id, coordinates, title, community }, ind) => (
      <Marker
        key={ind}
        position={coordinates} 
        onClick={() => {
                handleMarkerClick(ind, title, coordinates.lat, coordinates.lng);
              }}
              >
        {isOpen && infoWindowData?.id === ind && ( 
          <InfoWindow
            onCloseClick={() => {
              setIsOpen(false);
            }}
          >
            <p><Link to={`/accidents/${id}`}>{infoWindowData.title}</Link> <br />
            community: {community ? community : ''} <br />
            Lat: {coordinates.lat} <br />
            Lng: {coordinates.lng}
            </p>

          </InfoWindow>
        )} 
      </Marker>
        ))}

      </GoogleMap>
      )}
      </>
  )
}
export default LiveMap