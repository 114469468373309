import * as types from '../types'

const initialState = {
    totalLicenses: 0,
    totalMaleLicense: 0,
    totalFemaleLicense: 0,
    totalRegisterVehicles: 0,
    totalVehicles: 0,
    totalInjuries: 0,
    totalFatalities: 0,
    totalAccidents: 0,
    accidentPerMonth: [],
    accidentPerCounty: [],
    totalPersonInCountyPerGender: [],
    totalPersonInCountyPerCondition: [],
    totalPerson: 0,
    loading: false,
    error: null,
}

export const statisticReducer = (state=initialState, action) => {
    switch(action.type){
        case types.GET_STATISTICS:
            return {
                ...state,
                totalAccidents: action.payload,
                totalLicenses: action.totalLicenses,
                totalMaleLicense: action.totalMaleLicense,
                totalFemaleLicense: action.totalFemaleLicense,
                totalRegisterVehicles: action.totalRegisterVehicles,
                totalVehicles: action.totalVehicles,
                totalInjuries: action.totalInjuries,
                totalFatalities: action.totalFatalities,
                totalPerson: action.totalPerson,
                loading: false,
                error: null,
            }
        case types.GET_ACCIDENT_PER_COUNTY:
            return {
                ...state,
                accidentPerCounty: action.payload,
                loading: false,
                error: null,
            }
        case types.GET_PERSONS_IN_COUNTY_PER_GENDER:
            return {
                ...state,
                totalPersonInCountyPerGender: action.payload,
                loading: false,
                error: null,
            }
        case types.GET_PERSONS_IN_COUNTY_PER_CONDITION:
            return {
                ...state,
                totalPersonInCountyPerCondition: action.payload,
                loading: false,
                error: null,
            }
        case types.GET_ACCIDENT_PER_MONTH:
            return {
                ...state,
                accidentPerMonth: action.payload,
                loading: false,
                error: null,
            }
        case types.SET_LOADING:
            return {
                ...state,
                loading: true,
                error:null
            }
        case types.SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case types.RESET:
            return {
                ...state,
                loading: false,
                error: null
            }
        default:
            return state
    }
}