// import { type } from '@testing-library/user-event/dist/type'
import * as types from '../types'

const initialState = {
    licenses: [],
    license:{},
    loading: false,
    error: null,
    count: 0,
    total: 0,
    numberOfPages: 0,
    pagination: {}
}


export const licenseReducer = (state=initialState, action) => {
    switch(action.type){
        case types.GET_LICENSES:
            return {
                ...state,
                licenses: action.payload,
                numberOfPages: action.numberOfPages,
                pagination: action.pagination,
                license:null,
                loading: false,
                count: action.count,
                total: action.total,
                error: null
            }
        case types.GET_ONE_LICENSE:
            return {
                ...state,
                license: action.payload,
                loading: false,
                error: null
            }
        case types.SET_LOADING:
            return {
                ...state,
                loading: true,
                error:null
            }
        case types.SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case types.RESET:
            return {
                ...state,
                loading: false,
                error: null
            }
        default:
            return state
    }
}
