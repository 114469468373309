import React, { useEffect, useState, } from "react";
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {loginUser} from '../redux/actions/authAction'
import { toast } from "react-toastify";
// import Spinner from '../components/Spinner'

const Login = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [user, setUser] = useState({
        email: "",
        password: ""
    });

    // get destructured data from auth reducer
    const {loggedInUser, loggedIn,loading, error} = useSelector(state => state.authReducer);

    // destructured email and password from user state
    const { email, password } = user;

    // handle on change to handle
    const handleOnChange = (e) =>{
        setUser((prevState) =>({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    // pass destructed data from authreducer to use effect
    useEffect(() => {
        // check for error and show toast error
        if (error){
            toast.error(error)
        }
        // check if loggedin is true and redirect to dashboard
        if (loggedIn){
            toast.success('Login success')
            navigate('/dashboard')
        }
    }, [loggedInUser, loggedIn, loading, error, navigate]);



    const handleSubmit = (e) =>{
        e.preventDefault()
        const userData = {
            email,
            password,
        }
        console.log(userData)
        dispatch(loginUser(userData))
    }

    return (
        <>
            <div className="hold-transition login-page">
                <div className="login-box rounded=shadow-sm">
                    {/* login-logo */} 
                    <div className="card card-outline card-primary">
                        <div className="card-header text-center">
                            <Link to="/" className="h3"><b>Accident Crash&trade;</b></Link>
                        </div>
                        <div className="card-body">
                    <center><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Liberia_police1.svg/1200px-Liberia_police1.svg.png" alt="" width="200px" height='200px' /></center>

                            <p className="login-box-msg">Login to start your session</p>

                            <form onSubmit={handleSubmit} method="post">
                                <div className="input-group  mb-3">
                                    <input type="email" className="form-control" name="email" value={email} onChange={handleOnChange} placeholder="Email" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="password" className="form-control" value={password} name="password" onChange={handleOnChange} placeholder="Password" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-7">
                                        <div className="icheck-primary">
                                            <input type="checkbox" id="remember" />
                                            <label htmlFor="remember">&nbsp;Remember Me</label>
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <Link to='/privacypolicy' className=''>Privacy policy</Link>
                                    </div>
                                    {/* .col */}
                                    <div className="col-12 mt-2 mb-3">
                                        <button type="submit" className="btn btn-primary btn-block">{ loading? <i className="fas fa-loading">Loading</i>:'Login'}</button>
                                    </div>
                                    {/* .col */}
                                </div>
                            </form>

                            <p className="mb-1">
                                Forgot your password? <Link to="/forget-password">Click Here</Link>
                            </p>
                        </div>
                        {/* .card-body */}
                {/* login-box */}
                    <footer className="text-center ">
                        <p className="text-white text-bold">
                            Powered By
                            <span>
                                <a href="https://wwww.roviagatetechnology.com" target='_blank' className="text-primary text-bold" rel="noreferrer">
                                    &nbsp;RoviaGate Technology LLC
                                </a>
                            </span>
                        </p>
                        </footer>
                    </div>
                    {/* .card */}
                </div>
                {/* login-box */}

            </div>
            {/* </div> */}

        </>
    )
}

export default Login
