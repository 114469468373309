
function Spinner() {
  return (
    <>
      <div className="row">
        <div className="container">
          <div className="center">
            <div className='spinnerBox'>
              <div className='loadingSpinner'></div>
            </div>
          </div>
        </div>
      </div>

      <div className='loadingSpinnerContainer'></div>
    </>
  );
}

export default Spinner;
