import {Fragment, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {useParams} from 'react-router-dom'
import {getLicense} from '../../redux/actions/licenseAction'
import Spinner from "../../components/Spinner";
import { toast } from 'react-toastify'
import moment from 'moment'

const LicenseDetails = () => {
    // get id from route params
    const {id} = useParams()

    // get loading, error, and users data from userReducer
    const {license, error, loading} = useSelector(state => state.licenseReducer)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getLicense(id));
    }, [id, dispatch])


    if(error){
        toast.error(error)
    }

    console.log(license, loading)
    if (loading){
            // return <div> loading</div>
            return <Spinner/>
        }
    return(
        license?
            <>
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        {/* Profile Image */}
                        <div className="card card-primary card-outline">
                            <div className="card-body box-profile">
                                <div className="text-center">
                                    <img
                                        className="profile-user-img img-fluid img-circle"
                                        src="../assets/img/user4-128x128.jpg"
                                        alt="User"
                                    />
                                </div>
                                <h3 className="profile-username text-center">
                                    {license.fullName}
                                </h3>
                                <ul className="list-group list-group-unbordered mb-3">
                                    <li className="list-group-item">
                                        <b>License Number</b> <h6 className="float-right">{ license.licenseNumber }</h6>
                                    </li>
                                    <li className="list-group-item">
                                        <b>License Type</b> <h6 className="float-right">{ license.drivingLicense }</h6>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Name</b> <h6 className="float-right">{ license.fullName }</h6>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Category</b> <h6 className="float-right">{ license.Category }</h6>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Back Log</b> <h6 className="float-right">{ license.backLog }</h6>
                                    </li>
                                    {/*<li className="list-group-item">*/}
                                    {/*    <b>Record Type</b> <h6 className="float-right">{license.Online/DirectRecord }</h6>*/}
                                    {/*</li>*/}
                                    <li className="list-group-item">
                                        <b>Office</b> <h6 className="float-right">{ license.office }</h6>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Issue By</b> <h6 className="float-right">{license.issueBy}</h6>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Issue Date</b> <h6 className="float-right">{moment(license.issued).format("MMM-DD-YYYY")}</h6>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Expire Date</b> <h6 className="float-right">{ moment(license.expire).format("MMM-DD-YYYY") }</h6>
                                    </li>
                                </ul>
                            </div>
                            {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </div>

                    {/* /.col */}
                </div>

            </>
            :
            <Spinner />
    )
}

export default LicenseDetails;
