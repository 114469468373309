// import { toast } from 'react-toastify';
import { getUserToken, prodAxios } from "../../utils/sysConfig";
import * as types from "../types";

// GET ALL LICENSES
export const getLicenses =
  (page, entry, search, sort) => async (dispatch) => {
    try {
      setLoading(dispatch);
      // console.log(page);
      await prodAxios
        .get(
          `/api/v1/driverlicenses?page=${page}&limit=${entry}&search=${search}&sort=${sort}`,
          await getUserToken()
        )
        .then((res) => {
          console.log(res.data);
          dispatch({
            type: types.GET_LICENSES,
            payload: res.data.data,
            pagination: res.data.pagination,
            numberOfPages: res.data.numOfPages,
            total: res.data.totalDriverLicenses,
            count: res.data.numberOfLicense
          });
        })
        .catch((error) => {
          if (error.response === undefined) {
            // console.log('axios error', error.message)
            setError(dispatch, error.message);
          } else {
            // console.log('axios error', error.response.data.error)
            setError(dispatch, error.response.data.error);
          }
        });
    } catch (err) {
      console.log(err);
      setError(dispatch, err.message);
    }
  };

// GET SINGLE LICENSE
export const getLicense = (id) => async (dispatch) => {
  try {
    setLoading(dispatch);
    await prodAxios
      .get("/api/v1/driverlicenses/" + id, await getUserToken())
      .then((res) => {
        console.log(res.data.data);
        dispatch({
          type: types.GET_ONE_LICENSE,
          payload: res.data.data,
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response === undefined) {
          // console.log('axios error', error.message)
          setError(dispatch, error.message);
        } else {
          // console.log('axios error', error.response.data.error)
          setError(dispatch, error.response.data.error);
        }
      });
  } catch (error) {
    if (error.response === undefined) {
      console.log("axios error", error);
      setError(dispatch, error.message);
    } else {
      console.log("axios error", error);
      setError(dispatch, error.response.data.error);
    }
    // toast.error(err.message)
  }
};

export const reset = () => async (dispatch) => {
  dispatch({
    type: types.RESET,
  });
};

const setLoading = (dispatch) => {
  dispatch({
    type: types.SET_LOADING,
  });
};

const setError = (dispatch, error) => {
  dispatch({
    type: types.SET_ERROR,
    payload: error,
  });
};
