import Swal from 'sweetalert2'
import * as types from '../types';
// import { toast } from 'react-toastify';
import { getUserToken, prodAxios } from '../../utils/sysConfig';
import { toast } from 'react-toastify';


// GET ALL ACCIDENTS
export const getAccidents = (page, entry, search) => async dispatch => {

    try {
        // setLoading(dispatch)

        await prodAxios.get(`/api/v1/accidents?page=${page ? page : ''}&limit=${entry ? entry : ''}&search=${search ? search : ''}`, await getUserToken())
            .then((res) => {
                console.log(res)

                dispatch({
                    type: types.GET_ACCIDENTS,
                    payload: res.data.data,
                    pagination: res.data.pagination,
                    numberOfPages: res.data.numOfPages,
                    total: res.data.total,
                    count: res.data.count
                })
            })
            .catch((error) => {
                toast.error( error.response.data.error)
            })
    } catch (err) {
        toast.error( err.response.data.error)
    }
}


// GET ONE ACCIDENT ONLY
export const getAccident = (id) => async dispatch => {

    try {
        setLoading(dispatch)

        await prodAxios.get('/api/v1/accidents/' + id, await getUserToken())
            .then((res) => {
                console.log(res.data.data)

                dispatch({
                    type: types.GET_ONE_ACCIDENT,
                    payload: res.data.data
                })
            })
            .catch((error) => {
                Swal.fire({
                    title: error.code,
                    text: error.message,
                    icon: 'error',
                    footer: '<a href="">Why do I have this issue?</a>',
                    confirmButtonText: 'Ok',
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    }
                })
                if (error.response === undefined) {
                    setError(dispatch, error.message)
                } else {
                    setError(dispatch, error.response.data.error)
                }
            })
    } catch (error) {
        if (error.response === undefined) {
            setError(dispatch, error.message)
        } else {
            setError(dispatch, error.response.data.error)
        }
    }
}

// GET ONE ACCIDENT ONLY
export const updateAccident = (id, data) => async dispatch => {

    try {
        setLoading(dispatch)

        await prodAxios.put('/api/v1/accidents/' + id, data, await getUserToken())
            .then((res) => {
                console.log(res.data.data)

                dispatch({
                    type: types.UPDATE_ACCIDENT,
                    payload: res.data.data
                })
                // toast.success('Accident updated successfully!')

                Swal.fire({
                    title: 'Good job!',
                    text: 'Accident was successfully updated. ',
                    icon: 'success',
                    confirmButtonText: 'Ok',

                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    }
                })
            })
            .catch((err) => {
                const { message, response: { data: { error } } } = err
                if (error !== undefined) {
                    setError(dispatch, error)
                } else {
                    setError(dispatch, message)
                }
            })
    } catch (err) {
        setError(dispatch, err.message)
    }
}


// GET ONE ACCIDENT ONLY
export const deleteAccident = (id) => async dispatch => {

    try {
        setLoading(dispatch)

        await prodAxios.delete('/api/v1/accidents/' + id, await getUserToken())
            .then((res) => {
                console.log(res.data.data)

                dispatch({
                    type: types.DELETE_ACCIDENT,
                    payload: res.data.data
                })

                // toast.success('Deleted successfully!')

                Swal.fire({
                    title: 'Good job!',
                    text: 'Accident was successfully deleted. ',
                    icon: 'success',
                    confirmButtonText: 'Ok',

                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    }
                })
            })
            .catch((err) => {
                const { message, response: { data: { error } } } = err
                if (error !== undefined) {
                    setError(dispatch, error)
                } else {
                    setError(dispatch, message)
                }
            })
    } catch (err) {
        setError(dispatch, err.message)
    }
}

export const reset = () => async dispatch => {
    dispatch({
        type: types.RESET
    })
}

const setLoading = (dispatch) => {
    dispatch({
        type: types.SET_LOADING,
    });
}

const setError = (dispatch, error) => {
    dispatch({
        type: types.SET_ERROR,
        payload: error
    });

}
