import axios from "axios";
import { toast } from "react-toastify";
import * as types from "../types";
import { getUserToken, prodAxios } from "../../utils/sysConfig";

// GET LOGIN USER FUNCTION
export const getMe = () => async (dispatch) => {
  try {
    setLoading(dispatch);
    await axios
      .get("http://localhost:4000/api/v1/auth/me", await getUserToken())
      .then((res) => {
        const data = res.data.data;
        // get the
        let localStorageData = JSON.parse(localStorage.getItem("user"));
        localStorageData["user"] = data;
        //store the response token and user info in localstorage to keep the user login
        localStorage.setItem("user", JSON.stringify(localStorageData));
        //dispatch the USER LOGIN function to store the login user in global state
        dispatch({
          type: types.USER_LOGIN,
          payload: localStorageData,
          loading: false,
        });
      })
      .catch((error) => errorFunction(error, dispatch));
  } catch (error) {
    errorFunction(error, dispatch);
  }
};

// USER LOGIN FUNCTION
export const loginUser = (user) => async (dispatch) => {
  try {
    setLoading(dispatch);
    await prodAxios
      .post("/api/v1/auth/login", user)
      .then((res) => {
        const data = res.data;
        //store the response token and user info in localstorage to keep the user login
        localStorage.setItem("user", JSON.stringify(data));
        //dispatch the USER LOGIN function to store the login user in global state
        dispatch({
          type: types.USER_LOGIN,
          payload: data,
          loading: false,
        });

        runLogoutTimer(dispatch, data.timer);
      })
      .catch((error) => errorFunction(error, dispatch));
  } catch (error) {
    errorFunction(error, dispatch);
    // toast.error(err.message)
  }
};

export function runLogoutTimer(dispatch, timer) {
  setTimeout(() => dispatch(logoutUser()), timer);
}

// USER LOGOUT FUNCTION
export const logoutUser = () => async (dispatch) => {
  try {
    setLoading(dispatch);
    await prodAxios
      .get("/api/v1/auth/logout", await getUserToken())
      .then((res) => {
        console.log(res.data.data);
        const data = res.data;
        //dispatch the log out function to remove the login user from global state and
        dispatch({
          type: types.LOG_OUT,
          payload: data,
        });
        localStorage.clear();
        toast.success("Logout success");
      })
      .catch((error) => errorFunction(error, dispatch));
  } catch (error) {
    errorFunction(error, dispatch);
  }
};

// login user update password
export const updateUserPwd = (id, data) => async (dispatch) => {
  try {
    await axios
      .put(
        "http://localhost:4000/api/v1/auth/updatepassword/" + id,
        data,
        await getUserToken()
      )
      .then((res) => {
        console.log(res.data.data);
        const data = res.data;
        dispatch({
          type: types.RESET_PWD,
        });
        console.log(data);
        toast.success("Password updated successfully :) ");
      })
      .catch((error) => errorFunction(error, dispatch));
  } catch (err) {
    console.log(err);
    setError(dispatch, err.message);
  }
};

// user forget password function
export const forgetPwd = (email) => async (dispatch) => {
  try {
    //get the token from the localstorage
    await axios
      .post("http://localhost:4000/api/v1/auth/forgetpassword", { email })
      .then((res) => {
        console.log(res.data.data);
        const data = res.data;
        dispatch({
          type: types.RESET_PWD,
        });
        console.log(data);
        toast.success(data.data);
      })
      .catch((error) => errorFunction(error, dispatch));
  } catch (err) {
    console.log(err);
    toast.error(err);
  }
};

// user reset password function
export const resetPassword = (token, data) => async (dispatch) => {
  try {
    //get the token from the localstorage
    await axios
      .put("http://localhost:4000/api/v1/auth/resetpassword/" + token, data)
      .then((res) => {
        console.log(res.data.data);
        const data = res.data;
        // store login info in local storage
        localStorage.setItem("user", JSON.stringify(data));

        //dispatch the USER LOGIN function to store the login user in global state
        dispatch({
          type: types.USER_LOGIN,
          payload: data,
          loading: false,
        });
        // dispatch the reset password from the reducer to help us redirect to the dashboard
        dispatch({
          type: types.RESET_PWD,
        });
        console.log(data);
        toast.success("Password updated successfully");
      })
      .catch((error) => errorFunction(error, dispatch));
  } catch (err) {
    console.log(err);
    toast.error(err);
  }
};

// reset function to reset error and loading
export const reset = () => async (dispatch) => {
  dispatch({
    type: types.RESET,
  });
};

const setLoading = (dispatch) => {
  dispatch({
    type: types.SET_LOADING,
  });
};

const setError = (dispatch, error) => {
  dispatch({
    type: types.SET_ERROR,
    payload: error,
  });
};

const errorFunction = (error, dispatch) => {

  if (error.response === undefined) {
      console.log('logout')
    if (error.status === 401) {
        localStorage.clear()
        window.location.reload()
    }
    toast.error(error.message);
    setError(dispatch, error.message);
  } else {
    toast.error(error.response.data.error);
    if (error.response.status === 401) {
        localStorage.clear()
        window.location.reload()
    }
    setError(dispatch, error.response.data.error);
  }
};
