import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUsers } from "../../redux/actions/usersAction";
import ContentHeader from "../../components/layouts/ContentHeader";
import Pagination from "../../components/Pagination";
import Loader from "../../components/Loader";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import GeneratePdf from "../../components/GeneratePdf";


const AllUsers = () => {
  //select option for entries
  const selectOption = [10, 25, 50, 100];

  const {
    loginUser,
    loading,
    setPageNumber,
    data,
    start,
    count,
    total,
    end,
    pagination,
    pages,
    pageNumber,
    handleChange,
    goToNext,
    goToPrev,
    limit,
    search,
    setSearch,
  } = Pagination(
    (state) => state.userReducer.users,
    (state) => state.userReducer,
    (state) => state.authReducer.loggedInUser.user,
    getUsers,
    useState,
    useDispatch,
  );

  return (
    <>
      <ContentHeader title="Users" content="All Users" />
      {/*// <!-- Main content -->*/}
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className='card-title '>Users</h3>
              <div className="text-right">
                {loading ? (
                  <div>Loading...</div>
                ) : (
                  <>
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="download-table-xls-button btn-sm btn-success"
                      table="example1"
                      filename="users"
                      sheet="tablexls"
                        buttonText={<i className="far fa-file-excel"> Export XLS</i>} />&nbsp;
                    <button onClick={() => {
                      GeneratePdf(
                        data.map(user => [user.firstName + ' ' + user.middleName + ' ' + user.lastName, user.email, user.phone, user.role, user.agencies]),
                        [["Name", "Email", "Phone", "Role", "Agency"]],
                        'System users',
                        'user.pdf'
                      )
                    }} className="download-table-xls-button btn-sm btn-danger">
                      <i className="fa fa-file-pdf" /> Export PDF</button>
                  </>
                )}
              </div>
            </div>
            {/*// <!-- /.card-header -->*/}
            <div className="card-body">
              <div className="row mb-1">
                <div className="col-sm-12 col-md-6 col-12 d-inline-block">
                  <div className="dataTables_length d-inline">
                    <span>Show</span>
                    <select name="" defaultValue={limit} onChange={(e) => handleChange(e)} className="custom-select custom-select-sm form-control form-control-sm mx-2"
                      style={{ width: '70px' }}>
                      {selectOption.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                      ))}
                    </select>
                    <span>entries</span>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-12 d-inline-block" style={{ textAlign: 'right' }}>
                  <div id="example1_filter" className="dataTables_filter float-right">
                    <label style={{ fontWeight: 'normal', whiteSpace: 'nowrap', textAlign: 'left' }}>Search:<input
                      type="search" className="form-control form-control-sm" aria-label='search' value={search} onChange={(e) => setSearch(e.target.value)}
                      style={{ marginLeft: '0.5em', display: 'inline-block', width: 'auto' }} />
                    </label>
                  </div>
                </div>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <div>
                  <table
                    id="example1"
                    className="table table-bordered table-striped"
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Role</th>
                        {loginUser.role === 'Dev' ? <th>Agency</th> : null}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.map((user) => (
                          <tr key={user._id}>
                            <td>
                              {user.firstName +
                                " " +
                                user.middleName +
                                " " +
                                user.lastName}
                            </td>
                            <td>{user.email}</td>
                            <td>{user.phone}</td>
                            <td>{user.role}</td>
                            {loginUser.role === 'Dev' ? <td>{user.agencies}</td> : null}
                            <td>
                              <Link
                                to={`/users/${user._id}`}
                                className="btn btn-sm btn-primary m-1"
                              >
                                <i className="fa fa-eye"></i>
                              </Link>
                              <Link
                                to={`/users/edit/${user._id}`}
                                className="btn btn-sm btn-warning m-1"
                              >
                                <i className="fa fa-edit"></i>
                              </Link>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Role</th>
                        {loginUser.role === 'Dev' ? <th>Agency</th> : null}
                        <th>Action</th>
                      </tr>
                    </tfoot>
                  </table>
                  <br />

                  <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-5 col-12">
                      <div className="dataTables_info" role="status" aria-live="polite">
                        {/* {'Showing ' + pageNumber + ' to ' + count + ' of ' + Math.ceil(total) + ' entries'} */}
                        {"Showing " + (count * pageNumber - count + 1) + " to " + count * pageNumber + " of " + total + ' entries'}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-7 col-12">
                      <div className="dataTables_paginate paging_simple_numbers">
                        <ul className="pagination">
                          {/* <li className={"paginate_button page-item previous disabled" + (!pagination.prev ? "btn-disable" : null)}>
                            <Link className="page-link"
                              onClick={() => {
                                if (pagination.prev) {
                                  goToPrev(pagination.prev.page)
                                }
                              }}>Previous</Link>
                          </li> */}

                          {pages.slice(start, end).map((pageIndex) => (
                            <li className={"paginate_button page-item" + (pageNumber === pageIndex + 1 ? " active" : null)} key={pageIndex + 1} >
                              <Link key={pageIndex} onClick={() => setPageNumber(pageIndex + 1)} className="page-link">
                                {pageIndex + 1}
                              </Link>
                            </li>
                          ))}

                          {/* <li className={"paginate_button page-item next" + (!pagination.next ? "btn-disable" : null)}>
                            <Link className="page-link" onClick={() => {
                              if (pagination.next) {
                                goToNext(pagination.next.page)
                              }
                            }}>Next</Link>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/*<!-- /.card-body -->*/}
          </div>
          {/*/!*<!-- /.card -->*!/*/}
        </div>
        {/*/!*<!-- /.col -->*!/*/}
      </div>
      {/*/!*<!-- /.row -->*!/*/}
    </>
  );
};

export default AllUsers;