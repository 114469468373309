import axios from "axios"

export const getUserToken = async() => {
    let token = JSON.parse(localStorage.getItem('user')).token
    return { headers: { "Authorization": `Bearer ${token}` } }
}

export const userInfo = async () => {
    let { role, agencies } = JSON.parse(localStorage.getItem('user')).user
    return { role: role, agency: agencies }
}


export const prodAxios = axios.create({ baseURL: process.env.BASED_URL });