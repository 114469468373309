import ContentHeader from '../../components/layouts/ContentHeader'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import { getAccidents } from '../../redux/actions/accidentsAction'
import GeneratePdf from '../../components/GeneratePdf'
import Pagination from '../../components/Pagination'
// import Spinner from '../../components/Spinner'
import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

function AllAccidents() {
  //select option for entries
  const selectOption = [10, 25, 50, 100];

  const {
    user,
    total,
    loading,
    setPageNumber,
    data: accidents,
    start,
    end,
    numberOfPages,
    pagination,
    pages,
    pageNumber,
    handleChange,
    goToNext,
    goToPrev,
    limit,
    search,
    count,
    setSearch
  } = Pagination(state => state.accidentReducer.accidents, state => state.accidentReducer, state => state.authReducer.loggedInUser, getAccidents, useState, useDispatch)
  console.log(accidents)

  // if (loading) {
  //   return <Spinner />
  // }

  return (
    <Fragment>
      <ContentHeader title='Accident Crash DB v1' content='All Accidents' />

      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h3 className='card-title'>All Accidents</h3>
              <div className="text-right">
                {loading ? (
                  <div>Loading...</div>
                ) : (
                  <>
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="download-table-xls-button btn-sm btn-success font-bold"
                      table="example1"
                      filename="accidents"
                      sheet="accidents"
                      // buttonText="Export XLS"
                      buttonText={<i className="far fa-file-excel"> Export XLS</i>}
                    /> &nbsp;
                    {/* <button onClick={() => exportPDF(accidents)} className="download-table-xls-button btn-sm btn-danger"><i className="fa fa-file-pdf" /> Export PDF</button> */}

                    {/* Export PDF function */}
                    <button onClick={() => {
                      GeneratePdf(
                        accidents.map(accident => [accident.city, accident.county, accident.community, accident.roadLocation, accident.weather, accident.visibility, accident.status]),
                        [["City", "County", "Community", "Road Location", "Weather", "Visibility", "Status"]],
                        "Accidents Report",
                        "accidents_report.pdf"
                      )
                    }} className="download-table-xls-button btn-sm btn-danger">v
                      <i className="fa fa-file-pdf" /> Export PDF
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className='card-body table-responsive-md'>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-12 d-inline-block pb-1">
                  <div className="dataTables_length d-inline">
                    <span>Show</span>
                    <select name="" defaultValue={limit} onChange={(e) => handleChange(e)} className="custom-select custom-select-sm form-control form-control-sm mx-2"
                      style={{ width: '70px' }}>
                      {selectOption.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                      ))}
                    </select>
                    <span>entries</span>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-12 d-inline-block" style={{ textAlign: 'right' }}>
                  <div id="example1_filter" className="dataTables_filter float-right">
                    <label style={{ fontWeight: 'normal', whiteSpace: 'nowrap', textAlign: 'left' }}>Search:<input
                      type="search" className="form-control form-control-sm" aria-label='search' value={search} onChange={(e) => setSearch(e.target.value)}
                      style={{ marginLeft: '0.5em', display: 'inline-block', width: 'auto' }} />
                    </label>
                  </div>
                </div>
              </div>
              <br />
              {/* {loading ? <center>Loading...</center> : */}
              {/* <> */}
              <table id='example1' className='table-bordered table-striped table-sm' style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th>Officer Name</th>
                    <th>Acc. Title</th>
                    <th>City</th>
                    <th>County</th>
                    <th>Community</th>
                    <th>Weather</th>
                    <th>Visibility</th>
                    <th>Status</th>
                    <th colSpan={2} className='text-center'>
                      <i className='far fa-ellipsis-v-alt' />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {accidents &&
                    accidents.map((accident, index) => (
                      <tr key={accident._id}>
                        {/* <td>{index + 1}</td> */}
                        <td><Link to={`/accidents/${accident._id}`} className='text-bold'>{accident.user.firstName +  " " + accident.user.lastName}</Link></td>
                        <td>{accident.title}</td>
                        <td>{accident.city}</td>
                        <td>{accident.county}</td>
                        <td>{accident.community}</td>
                        <td>
                          <span className='badge rounded-pill bg-danger'>
                            {accident.weatherCondition}
                          </span>
                        </td>
                        <td>
                          <span className='badge rounded-pill bg-info'>
                            {accident.visibility}
                          </span>
                        </td>
                        <td>{accident.status}</td>
                        <td className='text-center'>
                          <Link to={`/accidents/${accident._id}`} className='btn btn-sm btn-primary'>
                            <i className='far fa-eye' />
                          </Link>
                        </td>
                        <td className='text-center'>
                          <Link to={`/accidents/edit/${accident._id}`} className='btn btn-sm btn-success'>
                            <i className='far fa-edit' />
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <br />

              <div className="row justify-content-center mt-">
                <div className="col-sm-12 col-md-5 col-12">
                  <div className="dataTables_info" role="status" aria-live="polite">
                    {"Showing " + (count * pageNumber - count + 1) + " to " + count * pageNumber + " of " + total + ' entries'}
                  </div>
                </div>
                <div className="col-sm-12 col-md-7 col-12">
                  <div className="dataTables_paginate paging_simple_numbers">
                    <ul className="pagination">
                      <li className={"paginate_button page-item previous disabled" + (!pagination.prev ? "btn-disable" : null)}>
                        <Link className="page-link"
                          onClick={() => {
                            if (pagination.prev) {
                              goToPrev(pagination.prev.page)
                            }
                          }}>Previous</Link>
                      </li>

                      {pages.slice(start, end).map((pageIndex) => (
                        <li className={"paginate_button page-item" + (pageNumber === pageIndex + 1 ? " active" : null)} key={pageIndex + 1} >
                          <Link key={pageIndex} onClick={() => setPageNumber(pageIndex + 1)} className="page-link">
                            {pageIndex + 1}
                          </Link>
                        </li>
                      ))}

                      <li className={"paginate_button page-item next" + (!pagination.next ? "btn-disable" : null)}>
                        <Link className="page-link" onClick={() => {
                          if (pagination.next) {
                            goToNext(pagination.next.page)
                          }
                        }}>Next</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* </>
              } */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AllAccidents;
