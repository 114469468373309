import { regVehicleReducer } from "./regVehicleReducer"
import { accidentReducer } from "./accidentReducer";
import { personsReducer } from "./personReducer";
import { vehiclesReducer } from "./vehicleReducer";
import { eventLogReducer } from "./loggerReducer";
import { licenseReducer } from './licenseReducer'
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { authReducer } from "./authReducer";
import { userReducer } from "./userReducer";
import { combineReducers } from "redux";
import { statisticReducer } from "./statisticReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};
const rootReducer = combineReducers({
  personsReducer,
  vehiclesReducer,
  userReducer,
  authReducer,
  statisticReducer,
  accidentReducer,
  regVehicleReducer,
  licenseReducer,
  eventLogReducer,
});
export default persistReducer(persistConfig, rootReducer);
