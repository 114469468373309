// import { type } from '@testing-library/user-event/dist/type'
import * as types from '../types'

const initialState = {
    users: [],
    user: {},
    loading: false,
    userAction: false,
    error: null,
    count: 0,
    total: 0,
    numberOfPages: 0,
    pagination: {}
}


export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_USERS:
            return {
                ...state,
                users: action.payload,
                userAction: false,
                loading: false,
                error: null,
                numberOfPages: action.numberOfPages,
                pagination: action.pagination,
                count: action.count,
                total: action.total,
            }
        case types.GET_ONE_USER:
            return {
                ...state,
                user: action.payload,
                loading: false,
                userAction: false,
                error: null
            }
        case types.CREATE_USER:
            return {
                ...state,
                user: action.payload,
                userAction: true,
                loading: false,
                error: null
            }
        case types.UPDATE_USER:
            return {
                ...state,
                user: action.payload,
                userAction: true,
                loading: false,
                error: null
            }
        case types.DELETE_USER:
            return {
                ...state,
                user: action.payload,
                loading: false,
                userAction: true,
                error: null
            }
        case types.SET_LOADING:
            return {
                ...state,
                loading: true,
                error: null
            }
        case types.SET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case types.RESET:
            return {
                ...state,
                loading: false,
                userAction: false,
                error: null
            }
        default:
            return state
    }
}