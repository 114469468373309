import jsPDF from "jspdf";
import "jspdf-autotable";

const GeneratePdf = (data, headers, title, fileName) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);

    // const title = "Register Vehicles Report";
    // const headers = [["Plate NO.", "Owner Name", "County", "Receipt NO.", "Type", "Color", "Make", "Model", "Year"]];

    // const data = reg_vehicles.map(vehicle => [vehicle.plateNumber, vehicle.ownerName, vehicle.county, vehicle.type, vehicle.color, vehicle.make, vehicle.model, vehicle.year]);

    let content = {
        startY: 50,
        head: headers,
        body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(fileName)
}

export default GeneratePdf

    // PDF Export
    // const download = e => {
    //     e.preventDefault();

    //     Axios({
    //         url: 'http://localhost:4000/api/v1/reg_vehicles',
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${user.token}`
    //         },
    //         // responseType: 'blob', // important
    //     }).then((response) => {
    //         console.log(response.data.data)
    //         saveAs(new Blob([response.data.data]), 'data.pdf');
    //     });
    // }
