import { toast } from "react-toastify";
import { getUserToken, prodAxios } from "../../utils/sysConfig";
import * as types from "../types";
import { setLoading } from "./defaultAction";

// GET TOTAL FOR ALL DATA
export const getStatistics = () => async (dispatch) => {
    try {
        setLoading(dispatch);
        await prodAxios
            .get(
                `/api/v1/statistics`,
                await getUserToken()
            )
            .then((res) => {
                const {
                    totalRegisterVehicles,
                    totalVehicles,
                    totalInjuries,
                    totalFatalities,
                    totalDriverLicenses,
                    totalMaleWithDriverLicenses,
                    totalFemaleWithDriverLicenses,
                    totalAccidents,
                    accidentPerMonth,
                    totalPerson
                } = res.data;
                dispatch({
                    type: types.GET_STATISTICS,
                    payload: totalAccidents,
                    totalLicenses: totalDriverLicenses,
                    totalMaleLicense: totalMaleWithDriverLicenses,
                    totalFemaleLicense: totalFemaleWithDriverLicenses,
                    totalRegisterVehicles: totalRegisterVehicles,
                    totalVehicles: totalVehicles,
                    totalInjuries: totalInjuries,
                    totalFatalities: totalFatalities,
                    accidentPerMonth: accidentPerMonth,
                    totalPerson: totalPerson
                });
            })
            .catch((error) => {
                toast.error(error.response.data.error)
            });
    } catch (error) {
        toast.error(error.response.data.error)
    }
};

// GET ACCIDENTS PER MONTH
export const getAccidentPerMonth = () => async (dispatch) => {
    try {
        setLoading(dispatch);
        await prodAxios
            .get(
                `/api/v1/statistics/accidentspermonth`,
                await getUserToken()
            )
            .then((res) => {
                const {
                    accidentPerMonth,
                } = res.data;

                dispatch({
                    type: types.GET_ACCIDENT_PER_MONTH,
                    payload: accidentPerMonth,
                });
            })
            .catch((error) => {
                toast.error(error.response.data.error)
            });
    } catch (error) {
        toast.error(error.response.data.error)
    }
};

// GET ACCIDENTS PER COUNTY
export const getAccidentPerCounty = () => async (dispatch) => {
    try {
        setLoading(dispatch);
        await prodAxios
            .get(
                `/api/v1/statistics/accidentspercounty`,
                await getUserToken()
            )
            .then((res) => {
                const {
                    accidentPerCounty,
                } = res.data;

                dispatch({
                    type: types.GET_ACCIDENT_PER_COUNTY,
                    payload: accidentPerCounty,
                });
            })
            .catch((error) =>{
                toast.error(error.response.data.error)
            });
    } catch (error) {
        toast.error(error.response.data.error)
    }
};

// GET TOTAL PERSON IN COUNTY PER GENDER
export const getPersonInCountyPerGender = () => async (dispatch) => {
    try {
        setLoading(dispatch);
        await prodAxios
            .get(
                `/api/v1/statistics/gender`,
                await getUserToken()
            )
            .then((res) => {
                // const {totalPersonInCountyPerGender} = res.data.data;
                dispatch({
                    type: types.GET_PERSONS_IN_COUNTY_PER_GENDER,
                    payload: res.data.data,
                });
            })
            .catch((error) => {
                toast.error(error.response.data.error)
            });
    } catch (error) {
        toast.error(error.response.data.error)
    }
};

// GET TOTAL PERSON IN COUNTY PER CONDITION
export const getPersonInCountyPerCondition = () => async (dispatch) => {
    try {
        setLoading(dispatch);
        await prodAxios
            .get(
                `/api/v1/statistics/personcondition`,
                await getUserToken()
            )
            .then((res) => {
                // const {
                //     totalPersonInCountyPerCondition,
                // } = res.data;
                dispatch({
                    type: types.GET_PERSONS_IN_COUNTY_PER_CONDITION,
                    payload: res.data.data,
                });
            })
            .catch((error) => {
                toast.error(error.response.data.error)
            });
    } catch (error) {
        toast.error(error.response.data.error)
    }
};