import { getUserToken, prodAxios } from '../../utils/sysConfig'
// import { toast } from 'react-toastify';
import * as types from '../types'
import { toast } from 'react-toastify'

// GET ALL ACCIDENTS
export const getRegVehicles = (page, entry, search) => async dispatch => {

    try {
        setLoading(dispatch)

        await prodAxios.get(`/api/v1/vehicleregistrations?page=${page}&limit=${entry}&search=${search}`, await getUserToken())
            .then((res) => {
                console.log(res.data.data)

                dispatch({
                    type: types.GET_VEHICLES,
                    payload: res.data.data,
                    pagination: res.data.pagination,
                    numberOfPages: res.data.numOfPages,
                    total: res.data.totalRegisterVehicles,
                    count: res.data.numberOfLicense,
                })
            })
            .catch((error) => {
                toast.error( error.response.data.error)
            })
    } catch (error) {
        console.log(error)
        setError(dispatch, error.message)
    }
}

// GET ONE ACCIDENT ONLY
export const getVehicle = (id) => async dispatch => {

    try {
        setLoading(dispatch)

        await prodAxios.get('/api/v1/vehicleregistrations/' + id, await getUserToken())
            .then((res) => {
                console.log(res.data.data)

                dispatch({
                    type: types.GET_ONE_VEHICLE,
                    payload: res.data.data
                })
            })
            .catch((error) => {
                toast.error( error.response.data.error)
            })
    } catch (error) {
        if (error.response === undefined) {
            setError(dispatch, error.message)
        } else {
            setError(dispatch, error.response.data.error)
        }
    }
}

// Constant functions
export const reset = () => async dispatch => {
    dispatch({
        type: types.RESET
    })
}

const setLoading = (dispatch) => {
    dispatch({
        type: types.SET_LOADING,
    });
}

const setError = (dispatch, error) => {
    dispatch({
        type: types.SET_ERROR,
        payload: error
    });

}
