import Swal from 'sweetalert2'
import moment from 'moment'
// import { toast } from 'react-toastify'
import Spinner from '../../components/Spinner'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom';
import ContentHeader from '../../components/layouts/ContentHeader'
import { getAccident, reset, deleteAccident, updateAccident } from '../../redux/actions/accidentsAction'


function EditAccident() {
    // create a loading local state
    const [updateLoading, setUpdateLoading] = useState(false);
    const [values, setValues] = useState({
        status: '',
        accidentReport: ''
    });

    // destructing the state
    const { status, accidentReport } = values;

    // Functions 
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { accident, loading, error, isSuccess } = useSelector(state => state.accidentReducer);
    console.log(loading, accident)

    useEffect(() => {
        dispatch(getAccident(params.id))
    }, [params, dispatch]);

    // Error handler
    useEffect(() => {
        if (error) {
            // toast.error(error)
            setUpdateLoading(false)

            Swal.fire({
                title: 'The Internet?',
                text: error,
                icon: 'error',
                footer: '<a href="">Please check your internet connection?</a>',
                confirmButtonText: 'Ok',
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                }
            })
        }

        dispatch(reset())
    }, [error, dispatch, isSuccess]);

    useEffect(() => {
        if (accident) {
            setValues({
                status: accident.status,
                accidentReport: accident.accidentReport,
            })
        }
    }, [accident])

    //  this function is getting the names and values for the form fields
    const handleAccidentInput = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    // handle the officer update
    const handleSubmit = (e) => {
        e.preventDefault();
        setUpdateLoading(true)

        const formData = { status, accidentReport }
        dispatch(updateAccident(params.id, formData))
    };

    if (isSuccess) {
        navigate('/accidents')
    }
    
    // Delete Accident
    const handleClick = () => {
        if (window.confirm("Are you show you want to delete this Accident?")) {
            dispatch(deleteAccident(params.id))
            navigate('/accidents')
        }
    }

    // if (loading) {
    //     return <Spinner />
    // }

    return (
        accident ?
            <Fragment>
                <ContentHeader title='Accident Crash DB v1' content='Edit Accident' />

                {/* <!-- Main content --> */}
                <section className="content">
                    <div className="container-fluid">
                        {/* <!-- ACCIDENT INFORMATION --> */}
                        <div className="card card-default">
                            <div className="card-header">
                                <h3 className="card-title">ACCIDENT INFORMATION</h3>

                                <div className="card-tools">
                                    <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                        <i className="fas fa-minus"></i>
                                    </button>
                                    <button type="button" className="btn btn-tool" data-card-widget="remove">
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>
                            {/* <!-- /.card-header --> */}
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 col-6 col-sm-6" style={{ display: 'none' }}>
                                        <div className="form-group">
                                            <label htmlFor='user'>Data Entry</label>
                                            <input type="text" name="user" value={accident.user} className='form-control' placeholder='Jeremiah F. Alfred' disabled="" />
                                        </div>
                                        {/* <!-- /.form-group --> */}
                                    </div>
                                    {/* <!-- /.col --> */}
                                    <div className="col-md-6 col-6 col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor='email'>Email</label>
                                            <input type="text" name="email" value={accident.email} className='form-control' placeholder='jeremiahalfred56@gmail.com' disabled="" />
                                        </div>
                                        {/* <!-- /.form-group --> */}
                                    </div>
                                    {/* <!-- /.col --> */}
                                    <div className="col-md-6 col-6 col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor='assignment'>Assignment</label>
                                            <input type="text" name="assignment" value={accident.assignment} className='form-control' placeholder='Traffic Inspector' disabled="" />
                                        </div>
                                        {/* <!-- /.form-group --> */}
                                    </div>
                                    {/* <!-- /.col --> */}
                                    <div className="col-md-6 col-6 col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor='city'>City</label>
                                            <input type="text" name="city" value={accident.city} className='form-control' placeholder='Monrovia' disabled="" />
                                        </div>
                                        {/* <!-- /.form-group --> */}
                                    </div>
                                    {/* <!-- /.col --> */}
                                    <div className="col-md-6 col-6 col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor='county'>County</label>
                                            <input type="text" name="county" value={accident.county} className='form-control' placeholder='Montserrado' disabled="" />
                                        </div>
                                        {/* <!-- /.form-group --> */}
                                    </div>
                                    {/* <!-- /.col --> */}
                                    <div className="col-md-6 col-6 col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor='community'>Community</label>
                                            <input type="text" name="community" value={accident.community} className='form-control' placeholder='Duport Road' disabled="" />
                                        </div>
                                        {/* <!-- /.form-group --> */}
                                    </div>
                                    {/* <!-- /.col --> */}
                                    <div className="col-md-6 col-6 col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor='roadLocation'>Road Location</label>
                                            {accident.roadLocation}
                                            <input type="text" name="roadLocation" value={accident.roadLocation} className='form-control' placeholder='Curve' disabled="" />
                                        </div>
                                        {/* <!-- /.form-group --> */}
                                    </div>
                                    {/* <!-- /.col --> */}
                                    <div className="col-md-6 col-6 col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor='visibility'>Visibility</label>
                                            <input type="text" name="visibility" value={accident.visibility} className='form-control' placeholder='Light' disabled="" />
                                        </div>
                                        {/* <!-- /.form-group --> */}
                                    </div>
                                    {/* <!-- /.col --> */}
                                    <div className="col-md-6 col-6 col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor='weatherCondition'>Weather Condition</label>
                                            <input type="text" name="weatherCondition" value={accident.weatherCondition} className='form-control' placeholder='Bad' disabled="" />
                                        </div>
                                        {/* <!-- /.form-group --> */}
                                    </div>
                                    {/* <!-- /.col --> */}
                                    <div className="col-6 col-sm-6" style={{ display: 'none' }}>
                                        <div className="form-group">
                                            <label htmlFor="customFile">Image</label>
                                            <div className="custom-file">
                                                <input type="file" name='image' className="custom-file-input" id="customFile" />
                                                <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                            </div>
                                        </div>
                                        {/* <!-- /.form-group --> */}
                                    </div>
                                    {/* <!-- /.col --> */}
                                </div>
                                {/* <!-- /.row --> */}
                            </div>
                            {/* <!-- /.card-body --> */}
                        </div>
                        {/* <!-- /.card --> */}

                        {/* <!-- CRASH INFORMATION --> */}
                        <div className="card card-default">
                            <div className="card-header">
                                <h3 className="card-title">CRASH INFORMATION</h3>

                                <div className="card-tools">
                                    <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                        <i className="fas fa-minus"></i>
                                    </button>
                                    <button type="button" className="btn btn-tool" data-card-widget="remove">
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>
                            {/* <!-- /.card-header --> */}
                            <div className="card-body">
                                <div className="row">
                                    {accident.crash ? accident.crash.map(crash => (
                                        <div className="col-md-6 col-6 col-sm-6" key={crash._id}>
                                            <div className="form-group">
                                                <label htmlFor='crashType'>Crash Type</label>
                                                <input type="text" name="crashType" value={crash.crashType} className='form-control' placeholder='Vehicle Only' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        // {/* <!-- /.col --> */ }
                                    )) : ''}
                                </div>
                                {/* <!-- /.row --> */}
                            </div>
                            {/* <!-- /.card-body --> */}
                        </div>
                        {/* <!-- /.card --> */}

                        {/* <!-- VEHICLE INFORMATION --> */}
                        {accident.vehicle ? accident.vehicle.map((car, index) => (
                            <div className="card card-default">
                                <div className="card-header">
                                    <h3 className="card-title">VEHICLE INFORMATION - {`#${index + 1}`}</h3>

                                    <div className="card-tools">
                                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                            <i className="fas fa-minus"></i>
                                        </button>
                                        <button type="button" className="btn btn-tool" data-card-widget="remove">
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                                {/* <!-- /.card-header --> */}
                                <div className="card-body" key={car._id}>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-6">
                                            <div className="form-group">
                                                <label htmlFor='bodyType'>Body Status</label>
                                                <input type="text" name="bodyType" value={car.bodyType} className='form-control' placeholder='New' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='brakeLight'>Brake Light</label>
                                                <input type="text" name="brakeLight" value={car.brakeLight} className='form-control' placeholder='Working' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='color'>Vehicle Color</label>
                                                <input type="text" name="color" value={car.color} className='form-control' placeholder='Dark Grey' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='headLight'>Head Light</label>
                                                <input type="text" name="headLight" value={car.headLight} className='form-control' placeholder='Damaged' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='model'>Vehicle Model</label>
                                                <input type="text" name="model" value={car.model} className='form-control' placeholder='2021' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='signalLight'>Signal Light</label>
                                                <input type="text" name="signalLight" value={car.signaLight} className='form-control' placeholder='Damaged' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='type'>Vehicle Type</label>
                                                <input type="text" name="type" value={car.type} className='form-control' placeholder='GMC' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='year'>Vehicle Year</label>
                                                <input type="text" name="year" value={moment(car.year).format('YYYY-M-D')} className='form-control' placeholder='2022' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='plateNumber'>License Plate Number</label>
                                                <input type="text" name="plateNumber" value={car.plateNumber} className='form-control' placeholder='5A25082' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                    </div>
                                    {/* <!-- /.row --> */}
                                </div>
                                {/* <!-- /.card-body --> */}
                            </div>
                        )) : ''}
                        {/* <!-- /.card --> */}

                        {/* <!-- PERSON INFORMATION --> */}
                        {accident.person ? accident.person.map((person, index) => (
                            <div className="card card-default" >
                                <div className="card-header">
                                    <h3 className="card-title">PERSON INFORMATION - {`#${index + 1}`}</h3>

                                    <div className="card-tools">
                                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                            <i className="fas fa-minus"></i>
                                        </button>
                                        <button type="button" className="btn btn-tool" data-card-widget="remove">
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                                {/* <!-- /.card-header --> */}
                                <div div className="card-body" key={person._id}>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='personID'>ID Type</label>
                                                <select className="form-control select2" name='personID' value={person.personID} style={{ width: "100%" }} disabled='disabled'>
                                                    <option>National ID</option>
                                                    <option>Driver</option>
                                                    <option>License</option>
                                                </select>
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='gender'>Gender</label>
                                                <select className="form-control select2 select2-danger" name='gender' value={person.gender} data-dropdown-css-class="select2-danger" style={{ width: "100%" }} disabled='disabled'>
                                                    <option>Male</option>
                                                    <option>Female</option>
                                                </select>
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='dateOfBirth'>DOB</label>
                                                <input type="text" name="dateOfBirth" value={person.dateOfBirth ? moment(person.dateOfBirth).format('YYYY-M-D') : ''} className='form-control' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='type'>Type</label>
                                                <input type="text" name="type" value={person.type} className='form-control' placeholder='Driver' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='finalDiagnosis'>Final Diagnosis</label>
                                                <input type="text" name="finalDiagnosis" value={person.finalDiagnosis} className='form-control' placeholder='Broken legs' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='dateOfExit'>Date of Exit</label>
                                                <input type="text" name="dateOfExit" value={person.dateOfExit} className='form-control' placeholder='2012-09-12' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='injurySeverity'>Injury Severity</label>
                                                <input type="text" name="injurySeverity" value={person.injurySeverity} className='form-control' placeholder='Severe' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='seatingPosition'>Seating Position</label>
                                                <input type="text" name="seatingPosition" value={person.seatingPosition} className='form-control' placeholder='Driver side' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='typeOfinjury'>Type of Injury</label>
                                                <input type="text" name="typeOfinjury" value={person.typeOfinjury} className='form-control' placeholder='Left leg injury' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                    </div>
                                    {/* <!-- /.row --> */}
                                </div>
                                {/* <!-- /.card-body --> */}
                            </div>
                        )) : ''}
                        {/* <!-- /.card --> */}

                        {/* <!-- ROAD INFORMATION --> */}
                        {accident.road ? accident.road.map((road, index) => (
                            <div className="card card-default">
                                <div className="card-header">
                                    <h3 className="card-title">ROAD INFORMATION - {`#${index + 1}`}</h3>

                                    <div className="card-tools">
                                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                            <i className="fas fa-minus"></i>
                                        </button>
                                        <button type="button" className="btn btn-tool" data-card-widget="remove">
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                                {/* <!-- /.card-header --> */}
                                <div className="card-body" key={road._id}>
                                    <div className="row">
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='type'>Type</label>
                                                <input type="text" name="type" value={road.type} className='form-control' placeholder='' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='Trafficontrol'>Traffic Control</label>
                                                <input type="text" name="Trafficontrol" value={road.Trafficontrol} className='form-control' placeholder='' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='roadSigmen'>Road Segment</label>
                                                <input type="text" name="roadSigmen" value={road.roadSigmen} className='form-control' placeholder='' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='surfaceCondition'>Surface Condition</label>
                                                <input type="text" name="surfaceCondition" value={road.surfaceCondition} className='form-control' placeholder='' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='roadObstacle'>Road Obstacle</label>
                                                <input type="text" name="roadObstacle" value={road.roadObstacle} className='form-control' placeholder='' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='roadSegmentGrade'>Road Segment Grade</label>
                                                <input type="text" name="roadSegmentGrade" value={road.roadSegmentGrade} className='form-control' placeholder='' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='legalSpeedLimit'>Legal Speed Limit</label>
                                                <input type="text" name="legalSpeedLimit" value={road.legalSpeedLimit} className='form-control' placeholder='' disabled="" />
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                    </div>
                                    {/* <!-- /.row --> */}
                                </div>
                                {/* <!-- /.card-body --> */}
                            </div>
                        )) : ''}
                        {/* <!-- /.card --> */}

                        {/* <!-- STATUS INFORMATION --> */}
                        <form onSubmit={handleSubmit} encType='multipart/form-data'>
                            <div className="card card-default">
                                {/* <!-- /.card-header --> */}
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6 col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='status'>Status</label>
                                                <select className="form-control select2" name='status' value={status} onChange={handleAccidentInput} style={{ width: "100%" }}>
                                                    <option>Select Status</option>
                                                    <option>Pending</option>
                                                    <option>Completed</option>
                                                    <option>In-Progress</option>
                                                    <option>Investigation</option>
                                                </select>
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor='accidentReport'>Accident Reports</label>
                                                <textarea className="form-control" name='accidentReport' value={accidentReport} onChange={handleAccidentInput} rows="3" placeholder="Enter ..."></textarea>
                                            </div>
                                            {/* <!-- /.form-group --> */}
                                        </div>
                                        {/* <!-- /.col --> */}
                                    </div>
                                    {/* <!-- /.row --> */}
                                </div>
                                {/* <!-- /.card-body --> */}
                                <div className="card-footer">
                                    {updateLoading ?
                                        <p className="float-left btn btn-primary submitButton mb-2">
                                            <i className="fa fa-spinner" /> Loading...
                                        </p>
                                        :
                                        <button type="submit" className="btn btn-info">Save</button>
                                    }
                                    <button type="submit" onClick={handleClick} className="btn btn-danger float-right">Delete</button>
                                </div>
                            </div>
                            {/* <!-- /.card --> */}
                        </form>
                    </div >
                    {/* <!-- /.container-fluid --> */}
                </section >
                {/* <!-- /.content --> */}
            </Fragment >
            : <Spinner />
    )
}

export default EditAccident
