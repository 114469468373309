import React from 'react'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'

function Footer() {
    // get the current date
    const currentDate = format(new Date(), 'yyyy')

    return (
        <footer className="main-footer">
            <strong>
                Copyright © {currentDate} <Link to="/" target='_blank'>Accident Crash Database</Link>.
            </strong>
            &nbsp;All rights reserved. | Powered by <strong><a href="https://www.roviagate.com" target='_blank' rel="noreferrer">Roviagate Technology, LLC</a></strong>
            <div className="float-right d-none d-sm-inline-block">
                <b>Version</b> 1.0.0
            </div>
        </footer>
    )
}

export default Footer