import { toast } from "react-toastify";
import { getUserToken, prodAxios } from "../../utils/sysConfig";
import * as types from "../types";

// GET ALL USERS
export const getUsers = (page, entry, search, sort) =>  async dispatch => {
  try {
    setLoading(dispatch)
    await prodAxios.get(
      `/api/v1/users?page=${page}&limit=${entry}&search=${search}&sort=${sort}`,
      await getUserToken()
    )
        .then((res) => {
          dispatch({
            type: types.GET_USERS,
            payload: res.data.data
          })

        })
        .catch((error) => {
          if (error.response === undefined){
            // console.log('axios error', error.message)
            setError(dispatch, error.message)
          }else{
            // console.log('axios error', error.response.data.error)
            setError(dispatch, error.response.data.error)
          }

        })
  } catch (err) {
    console.log(err);
    setError(dispatch, err.message);
  }
};

// GET SINGLE USER
export const getUser = (id) => async (dispatch) => {
  try {
    setLoading(dispatch);
    await prodAxios
      .get("/api/v1/users/" + id, await getUserToken())
      .then((res) => {
        dispatch({
          type: types.GET_ONE_USER,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        if (error.response === undefined) {
          // console.log('axios error', error.message)
          setError(dispatch, error.message);
        } else {
          // console.log('axios error', error.response.data.error)
          setError(dispatch, error.response.data.error);
        }
      });
  } catch (error) {
    if (error.response === undefined) {
      console.log("axios error", error);
      setError(dispatch, error.message);
    } else {
      console.log("axios error", error);
      setError(dispatch, error.response.data.error);
    }

    // toast.error(err.message)
  }
};

export const storeUser = (userData) => async (dispatch) => {
  try {
    setLoading(dispatch);
    await prodAxios
      .post("/api/v1/users/register", userData, await getUserToken())
      .then((res) => {
        dispatch({
          type: types.CREATE_USER,
          payload: res.data,
          userAction: true,
        });
        toast.success("User added successfully!");
      })
      .catch((err) => {
        const {
          message,
          response: {
            data: { error },
          },
        } = err;
        if (error !== undefined) {
          console.log("axios error", error);
          setError(dispatch, error);
        } else {
          console.log("second", err);
          setError(dispatch, message);
        }
      });
  } catch (err) {
    console.log(err);
    setError(dispatch, err.message);
  }
};

export const updateUser = (id, userData) => async (dispatch) => {
  try {
    setLoading(dispatch);
    await prodAxios
      .put("/api/v1/users/" + id, userData, await getUserToken())
      .then((res) => {
        dispatch({
          type: types.UPDATE_USER,
          payload: res.data.data,
          userAction: true,
        });
        toast.success("User updated successfully!");
      })
      .catch((err) => {
        const {
          message,
          response: {
            data: { error },
          },
        } = err;
        if (error !== undefined) {
          console.log("axios error", error);
          setError(dispatch, error);
        } else {
          console.log("second", err);
          setError(dispatch, message);
        }
      });
  } catch (err) {
    console.log(err);
    setError(dispatch, err.message);
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    setLoading(dispatch);
    await prodAxios
      .delete("/api/v1/users/" + id, await getUserToken())
      .then((res) => {
        dispatch({
          type: types.DELETE_USER,
          payload: res.data,
          userAction: true,
        });
        toast.success("User Deleted successfully!");
      })
      .catch((err) => {
        const {
          message,
          response: {
            data: { error },
          },
        } = err;
        if (error !== undefined) {
          console.log("axios error", error);
          setError(dispatch, error);
        } else {
          console.log("second", err);
          setError(dispatch, message);
        }
      });
  } catch (err) {
    console.log(err);
    setError(dispatch, err.message);
  }
};

export const reset = () => async (dispatch) => {
  dispatch({
    type: types.RESET,
  });
};

const setLoading = (dispatch) => {
  dispatch({
    type: types.SET_LOADING,
  });
};

const setError = (dispatch, error) => {
  dispatch({
    type: types.SET_ERROR,
    payload: error,
  });
};