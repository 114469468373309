import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  responsive: true,
  plugins: {
      legend: {
          position: 'top',
      },
      // title: {
      //     display: true,
      //     text: 'Chart.js Bar Chart',
      // },
  },
};

const DashboardPieChart = ({accidentPerCounty}) => {

  
  const labels = accidentPerCounty.map(accident => accident.county,);
  const data = {
    labels,
    datasets: [
      {
        // fill: true,
        label: 'Accidents Per County',
        data: accidentPerCounty.map(accident => accident.count),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(113,226,7,0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(27,54,29,0.2)',
          'rgba(251,125,3,0.2)',
          'rgba(23,22,22,0.2)',
          'rgba(219,2,2,0.2)',
          'rgba(5,73,245,0.2)',
          'rgba(51,8,241,0.2)',
          'rgba(6,244,175,0.2)',
          'rgba(34,15,1,0.2)',
          'rgba(157,136,28,0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgb(113,226,7)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(27,54,29,1)',
          'rgba(251,125,3,1)',
          'rgba(23,22,22,1)',
          'rgba(219,2,2,1)',
          'rgba(5,73,245,1)',
          'rgba(51,8,241, 1)',
          'rgba(6,244,175,1)',
          'rgba(34,15,1,1)',
          'rgba(157,136,28,1)',
          'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1,
      },
    ],
  };
  return <Bar options={options} data={data} />;
}

export default DashboardPieChart