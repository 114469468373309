import React, { useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../../redux/actions/authAction'

const Navbar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loggedInUser, loggedIn, loading, error } = useSelector(state => state.authReducer);

    useEffect(() => {
        if (error) {
            toast.error(error.error)
        }

    }, [loggedInUser, loggedIn, loading, error, navigate]);

    // user auth
    const user = loggedInUser.user;
    // logout function
    const logout = () => {
        dispatch(logoutUser())
    }

    return (
        <nav className="main-header navbar navbar-expand navbar-dark">
            {/* Left navbar links */}
            <ul className="navbar-nav">
                <li className="nav-item">
                    <Link className="nav-link" data-widget="pushmenu" to="#" role="button">
                        <i className="fas fa-bars" />
                    </Link>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <Link to="/dashboard" className="nav-link">
                        Home
                    </Link>
                </li>
            </ul>
            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
                {/* Navbar Search */}
                {/* <li className="nav-item">
                    <Link className="nav-link" data-widget="navbar-search" to="#" role="button">
                        <i className="fas fa-search" />
                    </Link>
                    <div className="navbar-search-block">
                        <form className="form-inline">
                            <div className="input-group input-group-sm">
                                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search"
                                />
                                <div className="input-group-append">
                                    <button className="btn btn-navbar" type="submit">
                                        <i className="fas fa-search" />
                                    </button>
                                    <button className="btn btn-navbar" type="button" data-widget="navbar-search">
                                        <i className="fas fa-times" />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </li> */}
                {/* Messages Dropdown Menu */}
                
                {/* Notifications Dropdown Menu */}
                {/* <li className="nav-item dropdown">
                    <Link className="nav-link" data-toggle="dropdown" to="#">
                        <i className="far fa-bell" />
                        <span className="badge badge-warning navbar-badge">15</span>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                        <span className="dropdown-item dropdown-header">
                            15 Notifications
                        </span>
                        <div className="dropdown-divider" />
                        <Link to="#" className="dropdown-item">
                            <i className="fas fa-envelope mr-2" /> 4 new messages
                            <span className="float-right text-muted text-sm">3 mins</span>
                        </Link>
                        <div className="dropdown-divider" />
                        <Link to="#" className="dropdown-item">
                            <i className="fas fa-users mr-2" /> 8 friend requests
                            <span className="float-right text-muted text-sm">12 hours</span>
                        </Link>
                        <div className="dropdown-divider" />
                        <Link to="#" className="dropdown-item">
                            <i className="fas fa-file mr-2" /> 3 new reports
                            <span className="float-right text-muted text-sm">2 days</span>
                        </Link>
                        <div className="dropdown-divider" />
                        <Link to="#" className="dropdown-item dropdown-footer">
                            See All Notifications
                        </Link>
                    </div>
                </li> */}
                <li className="nav-item">
                    <Link className="nav-link" data-widget="fullscreen" to="#" role="button">
                        <i className="fas fa-expand-arrows-alt" />
                    </Link>
                </li>
                <li className="nav-item dropdown">
                    <Link className="nav-link" data-toggle="dropdown" to="#">
                        <img src="/assets/img/user2-160x160.jpg" className="img-circle" height="25" alt="" />
                        &nbsp;&nbsp;
                        {'Hi, ' +user.lastName}
                    </Link >
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-center">
                        <div className="dropdown-divider" />
                        <Link to="/users/profile" className="dropdown-item">
                            <i className="fas fa-user mr-2" /> Profile
                        </Link>
                        <div className="dropdown-divider" />
                        <Link to="/users/profile#settings" className="dropdown-item">
                            <i className="fas fa-user-cog mr-2" /> Setting
                        </Link>
                        <div className="dropdown-divider" />
                        <Link onClick={() => logout()} className="dropdown-item">
                            <i className="fas fa-lock mr-2" /> Log out
                        </Link>
                    </div>
                </li >
            </ul >
        </nav >
    )
}

export default Navbar
