import { getUser, updateUser, reset } from '../redux/actions/usersAction'
import ContentHeader from "../components/layouts/ContentHeader";
import ChangePwdModal from "../components/ChangePwdModal";
import { Link } from 'react-router-dom'
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Spinner from "../components/Spinner";
import { toast } from 'react-toastify'

const Profile = () => {
  const dispatch = useDispatch()

  // create a loading local state
  const [updateLoading, setUpdateLoading] = useState(false);
  const [editUserState, setEditUserState] = useState({
    _id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    dob: "",
    email: "", 
    agencies: "",
    status: "",
    role: "",
    photo: "",
  });

  const {
    firstName,
    middleName,
    lastName,
    phone,
    dob,
    email,
    agencies,
    status,
    role,
    photo,
  } = editUserState;

  // get loading, error, and users data from userReducer
  const { loggedInUser } = useSelector(state => state.authReducer);
  const { user, loading, error } = useSelector(state => state.userReducer);

  const loginUser = loggedInUser.user;
  // console.log(loginUser._id)

  useEffect(() => {
    dispatch(getUser(loginUser._id));
  }, [loginUser, getUser])

  useEffect(() => {
    if (error) {
      toast.error(error)
      setUpdateLoading(false)
      dispatch(reset())
    }
  }, [dispatch, error]);

  const handleOnChange = (e) => {
    setEditUserState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  useEffect(() => {
    if (user) {
      setEditUserState({
        firstName: user.firstName,
        lastName: user.lastName,
        middleName: user.middleName,
        phone: user.phone,
        dob: user.dob,
        email: user.email,
        agencies: user.agencies,
        status: user.status,
        role: user.role,
        photo: user.photo,
      })
    }
  }, [user]);

  const handleSubmit = (e) => {
    setUpdateLoading(true)
    e.preventDefault()
    const userData = {
      firstName,
      middleName,
      lastName,
      phone,
      dob,
      email,
      agencies,
      status,
      role,
      photo,
    }

    // console.log(userData)
    dispatch(updateUser(loginUser._id, userData))

    setTimeout(() => {
      setUpdateLoading(false)
    }, 1000);
  }

  return (
    user ?
      <Fragment>
        <ContentHeader title='User Profile' content='user profile' />
        <div className="row">
          <div className="col-md-3">

            {/*// profile image*/}
            <div className="card card-primary card-outline">
              <div className="card-body box-profile">
                <div className="text-center">
                  <img className="profile-user-img img-fluid img-circle"
                    src={user.avatar !== 'place.png' ? user.avatar : '../../assets/img/user4-128x128.jpg'}
                    alt="User profile picture" />
                </div>
                <h3 className="profile-username text-center">{user.firstName + ' ' + user.middleName + ' ' + user.lastName}</h3>
                <p className="text-muted text-center">{user.role}</p>
                <ul className="list-group list-group-unbordered mb-3">
                  <li className="list-group-item">
                    <b>Email</b><h6><a className="float-right" href={`mailto:${user.email}`}>{user.email}</a></h6>
                  </li>
                  <li className="list-group-item">
                    <b>Agency</b> <h6 className="float-right">{user.agencies}</h6>
                  </li>
                  <li className="list-group-item">
                    <b>Mobile</b><a href={`tel:${user.phone}`} className="float-right">{user.phone}</a>
                  </li>
                  <li className="list-group-item">
                    <b>Date of Birth</b> <h6 className="float-right">{user.dob}</h6>
                  </li>
                  <li className="list-group-item">
                    <b>Role</b> <h6 className="float-right">{user.role}</h6>
                  </li>
                </ul>
                <Link to={`/users/edit/${user._id}`} className="float-right">
                  <b>{user.status === 'Active' ? <button className="float-right btn bg-danger">Deactivate</button> : <button className="float-right btn bg-success">Active</button>}</b>
                </Link>
              </div>
              {/*card-body*/}
            </div>
            {/*//card*/}

            {/*//About Me Box*/}
            {/* <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">About Me</h3>
              </div>
              <div className="card-body">
                <strong><i className="fas fa-book mr-1" /> Education</strong>
                <p className="text-muted"> B.S. in Computer Science from the University of Tennessee at Knoxville</p>
                <hr />
                <strong><i className="fas fa-map-marker-alt mr-1" /> Location</strong>
                <p className="text-muted">Malibu, California</p>
                <hr />
                <strong><i className="fas fa-pencil-alt mr-1" /> Skills</strong>
                <p className="text-muted">
                  <span className="tag tag-danger">UI Design</span>
                  <span className="tag tag-success">Coding</span>
                  <span className="tag tag-info">Javascript</span>
                  <span className="tag tag-warning">PHP</span>
                  <span className="tag tag-primary">Node.js</span>
                </p>
                <hr />
                <strong><i className="far fa-file-alt mr-1" /> Notes</strong>
                <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam fermentum enim neque.</p>
              </div>
            </div> */}
            {/*//card*/}
          </div>
          {/*// col*/}
          <div className="col-md-9">
            <div className="card">
              <div className="card-header p-2">
                <ul className="nav nav-pills">
                  <li className="nav-item"><a className="nav-link active" href="#settings" data-toggle="tab">Settings</a></li>
                  <li className="nav-item"><a className="nav-link" href="#activity" data-toggle="tab">Activity</a></li>
                  <li className="nav-item"><a className="nav-link" href="#timeline" data-toggle="tab">Timeline</a></li>
                </ul>
              </div>
              {/*//card-header*/}
              <div className="card-body">
                <div className="tab-content">
                  <div className="active tab-pane" id="settings">
                    <form className="form-horizontal" encType="multipart/form-data">
                      <div className="form-group row">
                        <label htmlFor="firstName" className="col-sm-2 col-form-label">First Name</label>
                        <div className="col-sm-10">
                          <input type="text" name='firstName' className="form-control" id="firstName" placeholder="Jeremiah" value={firstName || ''} onChange={handleOnChange} />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="middleName" className="col-sm-2 col-form-label">Middle Name</label>
                        <div className="col-sm-10">
                          <input type="text" name='middleName' className="form-control" id="middleName" placeholder="F" value={middleName || ''} onChange={handleOnChange} />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="lastName" className="col-sm-2 col-form-label">Last Name</label>
                        <div className="col-sm-10">
                          <input type="text" name='lastName' className="form-control" id="lastName" placeholder="Alfred" value={lastName || ''} onChange={handleOnChange} />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
                        <div className="col-sm-10">
                          <input type="email" name='email' className="form-control" id="email" placeholder="jeremiahalfreds56@gmail.com" value={email || ''} onChange={handleOnChange} />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="phoneNumber" className="col-sm-2 col-form-label">Mobile</label>
                        <div className="col-sm-10">
                          <input type="tel" name='phone' className="form-control" id="phoneNumber" placeholder="+231 770 428 099" value={phone || ''} onChange={handleOnChange} />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="dob" className="col-sm-2 col-form-label">Date of Birth</label>
                        <div className="col-sm-10">
                          <input type="date" name='dob' className="form-control" id="dob" value={dob || ''} onChange={handleOnChange} />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="agencies" className="col-sm-2 col-form-label">Agency</label>
                        <div className="col-sm-10">
                          <select name="agencies" className="custom-select" id="agencies" value={agencies || ''} onChange={handleOnChange}>
                            {(loggedInUser.role === 'Dev') ? (
                              <>
                                <option>Select</option>
                                <option>LNP</option>
                                <option>MPW</option>
                                <option>MOT</option>
                                <option>MOH</option>
                              </>
                            ) : <option>{agencies}</option>}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="status" className="col-sm-2 col-form-label">Status</label>
                        <div className="col-sm-10">
                          <select name="status" className="custom-select" id="status" value={status || ''} onChange={handleOnChange}>
                            {(loggedInUser.role === 'Dev') ? (
                              <>
                                <option>Select</option>
                                <option>Active</option>
                                <option>Not Active</option>
                                <option>Suspended</option>
                              </>
                            ) : <option>{status}</option>}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="role" className="col-sm-2 col-form-label">Role</label>
                        <div className="col-sm-10">
                          <select name="role" className="custom-select" id="role" value={role || ''} onChange={handleOnChange} >
                            {(loggedInUser.role === 'Dev') ? (
                              <>
                                <option>Select</option>
                                <option>Super Admin</option>
                                <option>Admin</option>
                                <option>DataEntry</option>
                                <option>User</option>
                              </>
                            ) : <option>{role}</option>}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="image" className="col-sm-2 col-form-label">Avatar</label>
                        <div className="col-sm-10">
                          <input className="form-control" type="file" name="photo" onChange={handleOnChange} value={photo || ''} />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="offset-sm-2 col-sm-10">
                          {updateLoading ?
                            <p className="float-left btn btn-primary submitButton mb-2">
                              <i className="fa fa-spinner" /> Loading...
                            </p>
                            :
                            <button type="submit" onClick={handleSubmit} className="btn btn-danger">Submit</button>
                          }
                        </div>
                      </div>
                    </form>
                    {/* Reset Password */}
                    <button className="float-right btn btn-info" data-toggle="modal" data-target="#changePwd">
                      <b>Change Password</b>
                    </button>
                    <ChangePwdModal />
                    {/* Reset Password */}
                  </div>
                  {/*//tab-pane*/}
                  <div className="tab-pane" id="activity">
                    {/*//Post*/}
                    <div className="post">
                      <div className="user-block">
                        <img className="img-circle img-bordered-sm" src="../../assets/img/user1-128x128.jpg"
                          alt="user image" />
                        <span className="username">
                          <a href="#">Jonathan Burke Jr.</a>
                          <a href="#" className="float-right btn-tool"><i className="fas fa-times" /></a>
                        </span>
                        <span className="description">Shared publicly - 7:30 PM today</span>
                      </div>
                      {/*//user-block*/}
                      <p>
                        Lorem ipsum represents a long-held tradition for designers,
                        typographers and the like. Some people hate it and argue for
                        its demise, but others ignore the hate as they create awesome
                        tools to help create filler text for everyone from bacon lovers
                        to Charlie Sheen fans.
                      </p>

                      <p>
                        <a href="#" className="link-black text-sm mr-2"><i className="fas fa-share mr-1" /> Share</a>
                        <a href="#" className="link-black text-sm"><i
                          className="far fa-thumbs-up mr-1" /> Like</a>
                        <span className="float-right">
                          <a href="#" className="link-black text-sm">
                            <i className="far fa-comments mr-1" /> Comments (5)
                          </a>
                        </span>
                      </p>

                      <input className="form-control form-control-sm" type="text" placeholder="Type a comment" />
                    </div>
                    {/*//post*/}

                    {/*//Post*/}
                    <div className="post clearfix">
                      <div className="user-block">
                        <img className="img-circle img-bordered-sm" src="../../assets/img/user7-128x128.jpg"
                          alt="User Image" />
                        <span className="username">
                          <a href="#">Sarah Ross</a>
                          <a href="#" className="float-right btn-tool"><i className="fas fa-times" /></a>
                        </span>
                        <span className="description">Sent you a message - 3 days ago</span>
                      </div>
                      {/*//user-block*/}
                      <p>
                        Lorem ipsum represents a long-held tradition for designers,
                        typographers and the like. Some people hate it and argue for
                        its demise, but others ignore the hate as they create awesome
                        tools to help create filler text for everyone from bacon lovers
                        to Charlie Sheen fans.
                      </p>

                      <form className="form-horizontal">
                        <div className="input-group input-group-sm mb-0">
                          <input className="form-control form-control-sm" placeholder="Response" />
                          <div className="input-group-append">
                            <button type="submit" className="btn btn-danger">Send</button>
                          </div>
                        </div>
                      </form>
                    </div>
                    {/*//post*/}

                    {/*//Post*/}
                    <div className="post">
                      <div className="user-block">
                        <img className="img-circle img-bordered-sm" src="../../assets/img/user6-128x128.jpg"
                          alt="User Image" />
                        <span className="username">
                          <a href="#">Adam Jones</a>
                          <a href="#" className="float-right btn-tool"><i className="fas fa-times" /></a>
                        </span>
                        <span className="description">Posted 5 photos - 5 days ago</span>
                      </div>
                      {/*//user-block*/}
                      <div className="row mb-3">
                        <div className="col-sm-6">
                          <img className="img-fluid" src="../../assets/img/photo1.png" alt="Photo" />
                        </div>
                        {/*//col*/}
                        <div className="col-sm-6">
                          <div className="row">
                            <div className="col-sm-6">
                              <img className="img-fluid mb-3" src="../../assets/img/photo2.png" alt="Photo" />
                              <img className="img-fluid" src="../../../assets/img/photo3.jpg" alt="Photo" />
                            </div>
                            {/*//col*/}
                            <div className="col-sm-6">
                              <img className="img-fluid mb-3" src="../../assets/img/photo4.jpg" alt="Photo" />
                              <img className="img-fluid" src="../../assets/img/photo1.png" alt="Photo" />
                            </div>
                            {/*//col*/}
                          </div>
                          {/*//row*/}
                        </div>
                        {/*//col*/}
                      </div>
                      {/*//row*/}

                      <p>
                        <a href="#" className="link-black text-sm mr-2"><i className="fas fa-share mr-1" /> Share</a>
                        <a href="#" className="link-black text-sm"><i
                          className="far fa-thumbs-up mr-1" /> Like</a>
                        <span className="float-right">
                          <a href="#" className="link-black text-sm">
                            <i className="far fa-comments mr-1" /> Comments (5)
                          </a>
                        </span>
                      </p>

                      <input className="form-control form-control-sm" type="text" placeholder="Type a comment" />
                    </div>
                    {/*//post*/}
                  </div>
                  {/*//tab-pane*/}
                  <div className="tab-pane" id="timeline">
                    {/*//The timeline*/}
                    <div className="timeline timeline-inverse">
                      {/*//timeline time label*/}
                      <div className="time-label">
                        <span className="bg-danger">
                          10 Feb. 2014
                        </span>
                      </div>
                      {/*//timeline-label*/}
                      {/*//timeline item*/}
                      <div>
                        <i className="fas fa-envelope bg-primary" />

                        <div className="timeline-item">
                          <span className="time"><i className="far fa-clock" /> 12:05</span>

                          <h3 className="timeline-header"><a href="#">Support Team</a> sent you an email</h3>

                          <div className="timeline-body">
                            Etsy doostang zoodles disqus groupon greplin oooj voxy zoodles,
                            weebly ning heekya handango imeem plugg dopplr jibjab, movity
                            jajah plickers sifteo edmodo ifttt zimbra. Babblely odeo kaboodle
                            quora plaxo ideeli hulu weebly balihoo...
                          </div>
                          <div className="timeline-footer">
                            <a href="#" className="btn btn-primary btn-sm">Read more</a>
                            <a href="#" className="btn btn-danger btn-sm">Delete</a>
                          </div>
                        </div>
                      </div>
                      {/*//END timeline item*/}
                      {/*//timeline item*/}
                      <div>
                        <i className="fas fa-user bg-info" />

                        <div className="timeline-item">
                          <span className="time"><i className="far fa-clock" /> 5 mins ago</span>

                          <h3 className="timeline-header border-0"><a href="#">Sarah Young</a> accepted your friend
                            request
                          </h3>
                        </div>
                      </div>
                      {/*//END timeline item*/}
                      {/*//timeline item*/}
                      <div>
                        <i className="fas fa-comments bg-warning" />

                        <div className="timeline-item">
                          <span className="time"><i className="far fa-clock" /> 27 mins ago</span>

                          <h3 className="timeline-header"><a href="#">Jay White</a> commented on your post</h3>

                          <div className="timeline-body">
                            Take me to your leader!
                            Switzerland is small and neutral!
                            We are more like Germany, ambitious and misunderstood!
                          </div>
                          <div className="timeline-footer">
                            <a href="#" className="btn btn-warning btn-flat btn-sm">View comment</a>
                          </div>
                        </div>
                      </div>
                      {/*//END timeline item*/}
                      {/*//timeline time label*/}
                      <div className="time-label">
                        <span className="bg-success">
                          3 Jan. 2014
                        </span>
                      </div>
                      {/*//timeline-label*/}
                      {/*//timeline item*/}
                      <div>
                        <i className="fas fa-camera bg-purple" />

                        <div className="timeline-item">
                          <span className="time"><i className="far fa-clock" /> 2 days ago</span>

                          <h3 className="timeline-header"><a href="#">Mina Lee</a> uploaded new photos</h3>

                          <div className="timeline-body">
                            <img src="https://placehold.it/150x100" alt="..." />
                            <img src="https://placehold.it/150x100" alt="..." />
                            <img src="https://placehold.it/150x100" alt="..." />
                            <img src="https://placehold.it/150x100" alt="..." />
                          </div>
                        </div>
                      </div>
                      {/*//END timeline item*/}
                      <div>
                        <i className="far fa-clock bg-gray" />
                      </div>
                    </div>
                  </div>
                  {/*//tab-pane*/}
                </div>
                {/*//tab-content*/}
              </div>
              {/*//card-body*/}
            </div>
            {/*//card*/}
          </div>
          {/*//col*/}
        </div>
      </Fragment>
      :
      <Spinner />
  )
}

export default Profile