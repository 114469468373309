import { Fragment } from 'react'
import { Link } from 'react-router-dom';
import img from "../assets/images/not-found.svg";

function Error() {
    return (
        <Fragment>
            <div className="container">
                <center>
                    <img className="error-img" src={img} alt="not found" style={{ width: '50%', height: '50%', marginTop: '10%' }} />
                    <p>It's just a <strong style={{ fontSize: '23px' }}>404.</strong>.</p>
                    <div>
                        <p>You didn't break the internet, but the request URL <b>{window.location.pathname}</b> you're looking for was not found on this server. </p>
                        <Link to='/dashboard' className='btn btn-info'>Go Home </Link>
                    </div>
                </center>
            </div>
        </Fragment >
    )
}

export default Error