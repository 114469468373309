import ContentHeader from '../components/layouts/ContentHeader'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import { getLoggers } from '../redux/actions/loggerAction'
import GeneratePdf from '../components/GeneratePdf'
import Pagination from '../components/Pagination'
// import Spinner from '../components/Spinner'
import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

function Logger() {
    //select option for entries
    const selectOption = [10, 25, 50, 100];

    const {
        user,
        total,
        loading,
        setPageNumber,
        data: eventLogs,
        start,
        end,
        numberOfPages,
        pagination,
        pages,
        pageNumber,
        handleChange,
        goToNext,
        goToPrev,
        limit,
        search,
        count,
        setSearch
    } = Pagination(state => state.eventLogReducer.eventLogs, state => state.eventLogReducer, state => state.authReducer.loggedInUser, getLoggers, useState, useDispatch)

    console.log(pagination, pages, pageNumber, start, end)

    return (
        <Fragment>
            <ContentHeader title='Accident Crash DB v1' content='Activity Logs' />

            <div className='row'>
                <div className='col-12'>
                    <div className='card'>
                        <div className='card-header'>
                            <h3 className='card-title '>Activity Logs</h3>
                            <div className="text-right">
                                {loading ? (
                                    <div>Loading...</div>
                                ) : (
                                    <>
                                        <ReactHTMLTableToExcel
                                            id="test-table-xls-button"
                                            className="download-table-xls-button btn-sm btn-success"
                                            table="example1"
                                            filename="activities_logs"
                                            sheet="activities_logs"
                                            // buttonText="Export XLS"
                                            buttonText={<i className="far fa-file-excel"> Export XLS</i>}
                                        /> &nbsp;
                                        {/* <button onClick={() => exportPDF(eventLogs)} className="download-table-xls-button btn-sm btn-danger"><i className="fa fa-file-pdf" /> Export PDF</button> */}

                                        {/* Export PDF function */}
                                        <button onClick={() => {
                                            GeneratePdf(
                                                eventLogs.map(log => [log.level, log.message]),
                                                [["Level", "Message"]],
                                                "Activity logs Report",
                                                "activity_logs.pdf"
                                            )
                                        }} className="download-table-xls-button btn-sm btn-danger">
                                            <i className="fa fa-file-pdf" /> Export PDF
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className='card-body table-responsive-md'>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-12 d-inline-block pb-1">
                                    <div className="dataTables_length d-inline">
                                        <span>Show</span>
                                        <select name="" defaultValue={limit} onChange={(e) => handleChange(e)} className="custom-select custom-select-sm form-control form-control-sm mx-2"
                                            style={{ width: '70px' }}>
                                            {selectOption.map((option, index) => (
                                                <option key={index} value={option}>{option}</option>
                                            ))}
                                        </select>
                                        <span>entries</span>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-12 d-inline-block" style={{ textAlign: 'right' }}>
                                    <div id="example1_filter" className="dataTables_filter float-right">
                                        <label style={{ fontWeight: 'normal', whiteSpace: 'nowrap', textAlign: 'left' }}>Search:<input
                                            type="search" className="form-control form-control-sm" aria-label='search' value={search} onChange={(e) => setSearch(e.target.value)}
                                            style={{ marginLeft: '0.5em', display: 'inline-block', width: 'auto' }} />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <br />
                            {loading ? <center>Loading...</center> :
                                <>
                                    <table id='example1' className='table-sm table-bordered table-striped' style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>ID #</th>
                                                <th>Level</th>
                                                <th>Message</th>
                                                {/* <th className='center'>
                                            <i className="fas fa-ellipsis-h-alt"></i>
                                        </th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {eventLogs &&
                                                eventLogs.map((log, index) => (
                                                    <tr key={log._id}>
                                                        {/* <td>{index}</td> */}
                                                        <td><Link to={`/log-details/${log._id}`} className='text-bold'>{log._id}</Link></td>
                                                        <td>{log.level}</td>
                                                        <td>{log.message}</td>
                                                        {/* <td className='text-center'>
                                                    <Link to={`/register-logs/${log._id}`} className='btn btn-sm btn-primary'>
                                                        <i className='far fa-eye' />
                                                    </Link>
                                                </td> */}
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    <br />

                                    <div className="row justify-content-center mt-">
                                        <div className="col-sm-12 col-md-5 col-12">
                                            <div className="dataTables_info" role="status" aria-live="polite">
                                                {/* {'Showing ' + pageNumber + ' to ' + count + ' of ' + Math.ceil(total) + ' entries'} */}
                                                {"Showing " + (count * pageNumber - count + 1) + " to " + count * pageNumber + " of " + total + ' entries'}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-7 col-12">
                                            <div className="dataTables_paginate paging_simple_numbers">
                                                <ul className="pagination">
                                                    <li className={"paginate_button page-item previous disabled" + (!pagination.prev ? "btn-disable" : null)}>
                                                        <Link className="page-link"
                                                            onClick={() => {
                                                                if (pagination.prev) {
                                                                    goToPrev(pagination.prev.page)
                                                                }
                                                            }}>Previous</Link>
                                                    </li>

                                                    {pages.slice(start, end).map((pageIndex) => (
                                                        <li className={"paginate_button page-item" + (pageNumber === pageIndex + 1 ? " active" : null)} key={pageIndex + 1} >
                                                            <Link key={pageIndex} onClick={() => setPageNumber(pageIndex + 1)} className="page-link">
                                                                {pageIndex + 1}
                                                            </Link>
                                                        </li>
                                                    ))}

                                                    <li className={"paginate_button page-item next" + (!pagination.next ? "btn-disable" : null)}>
                                                        <Link className="page-link" onClick={() => {
                                                            if (pagination.next) {
                                                                goToNext(pagination.next.page)
                                                            }
                                                        }}>Next</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >
    )
}

export default Logger