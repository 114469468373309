import * as types from "../types";
import {toast} from "react-toastify";

export const reset = () => async (dispatch) => {
    dispatch({
        type: types.RESET,
    });
};

export const setLoading = (dispatch) => {
    dispatch({
        type: types.SET_LOADING,
    });
};

const setError = (dispatch, error) => {
    dispatch({
        type: types.SET_ERROR,
        payload: error,
    });
};

export const errorFunction = (error, dispatch) => {
    if (error.response === undefined) {
        console.log("logout");
        if (error.status === 401) {
            localStorage.clear();
            window.location.reload();
        }
        toast.error(error.message);
        setError(dispatch, error.message);
    } else {
        toast.error(error.response.data.error);
        if (error.response.status === 401) {
            localStorage.clear();
            window.location.reload();
        }
        setError(dispatch, error.response.data.error);
    }
};