import React from "react";

const PrivacyPolicy = () => {

    return (
        <>
            <div className="container">
            <div className="col">
                <h2 className="display-4">PRIVACY POLICY</h2>
                Last Updated on April 11, 2023
                <p>At FindOfficer, we are committed to protecting the privacy of our users. This Privacy Policy outlines how we collect, use, and share information when you use our mobile and web-based application.</p>
            </div>
            <div>
                <p>Information Collection and Use <br />When you use ACDB, we may collect the following information:</p>
            </div>

            <div>
                <p><b>Personal Information:</b> <br />We may collect personal information, such as your name, email address, phone number, and location.</p>
            </div>

            {/* <div>
                <p className="">Complaint and Applaud Information:</p>
                <p>We may collect information related to complaints and applauds of officers submitted by you, including details of the incident, location, date, time, and any supporting evidence.</p>
            </div> */}

            <div>
                <p>Device Information: <br />We may collect device-specific information such as the type of device you are using, the operating system, and unique device identifiers.</p>
            </div>

            <div>
                <p>We use this information to provide you with the best possible service, including:<br />Handling Complaints and Applauds:<br />
                We use the information you provide to handle and respond to complaints and applauds submitted by you.</p>
            </div>

            <div>
            Improving the Service: <br />
We use the information we collect to improve the quality of our service, including the functionality, user experience, and performance of our application.

            </div>
            <br />
            <div>
                <p>Communication: <br />
                We may use your contact information to communicate with you regarding the status of your complaint/applaud, service updates, and other related information.
                </p>
            </div>

            <div>
                <p>
                Sharing of Information <br />
                We may share your information with third parties only in the following circumstances:
                </p>
            </div>
            <div>
                <p>
                    With Your Consent: <br />
                    We may share your information with third parties if you have given us explicit consent to do so.
                </p>
            </div>

            <div>
                <p>
                Legal Requirements: <br />
                We may share your information when we believe it is necessary to comply with legal obligations or to protect the rights, property, or safety of FindOfficer, our users, or others.
                </p>
            </div>

            <div>
                <p>
                Other Online Services and Third-Party Features <br />
                Our website and mobile app may contain links to other websites owned and operated by third parties. These may include features such as apps, social media buttons or links, and payment services. These third-party sites may collect information about you if you click on a link and the social media sites may automatically record information about your browsing behavior every time you visit a site that has a social media button. The privacy practices of these third parties on the information they may obtain about you is subject to their privacy policies. We recommend that you review them.
                </p>
            </div>
            
            <div>
                <p>
                Data Security <br />
                LAMS takes data security seriously and has implemented reasonable measures to protect your information from unauthorized access, use, and disclosure. We use industry-standard security protocols to ensure the confidentiality and integrity of your personal information. We utilize commercially reasonable administrative, technical, and physical safeguards to protect the confidentiality and security of your personal information against accidental, unlawful, or unauthorized destruction, loss, alteration, access, disclosure, or use. There is no guarantee that the information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards.
                </p>
            </div>

            <div>
                <p>
                Changes to this Privacy Policy <br />
                We may update this Privacy Policy from time to time, and we will notify you of any changes by posting the updated policy on our website. Your continued use of our service after any modifications to this Privacy Policy will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.
                </p>
            </div>

            <div>
                <p>
                Contact Us <br />
                If you have any questions about our Privacy Policy or want to make a complaint, please contact us at info@roviagatetechnology.com. We will respond to your inquiry within a reasonable time.
                </p>
            </div>

            <div>
            By using Liberia Accident Manageent System, you consent to the collection, use, and sharing of your information as outlined in this Privacy Policy.
                <p></p>
            </div>
                </div>
        </>
    )
}

export default PrivacyPolicy;
