import { useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import {getUser} from '../../redux/actions/usersAction'
import Spinner from "../../components/Spinner";
import { toast } from 'react-toastify'

const ShowUser = () =>{
    // get id from route params
    const {id} = useParams()

    // get loading, error, and users data from userReducer
    const {loading, error, user} = useSelector(state => state.userReducer)

    const dispatch = useDispatch()

  useEffect(() => {

      dispatch(getUser(id));

  }, [id, dispatch])

    if (loading){
        return <Spinner/>
    }

    if(error){
        toast.error(error)
    }

  console.log(user)
//    useEffect(() =>{
//    })
//    const users = useSelector(state => state.userReducer.users)

//    var user = users.find(function(user) {
//     return user.id == id;
//     });

    return(
        user?
        <>
            <div className="row justify-content-center">
                <div className="col-md-6">
                    {/* Profile Image */}
                    <div className="card card-primary card-outline">
                        <div className="card-body box-profile">
                            <div className="text-center">
                                <img
                                    className="profile-user-img img-fluid img-circle"
                                    src="../assets/img/user4-128x128.jpg"
                                    alt="User"
                                />
                            </div>
                            <h3 className="profile-username text-center">
                                {user.name}
                            </h3>
                            <p className="text-muted text-center">Software Engineer</p>
                            <ul className="list-group list-group-unbordered mb-3">
                                <li className="list-group-item">
                                    <b>Name</b> <h6 className="float-right">{ user.firstName+' '+user.middleName+' '+user.lastName }</h6>
                                </li>
                                <li className="list-group-item">
                                    <b>Email</b> <h6 className="float-right">{ user.email }</h6>
                                </li>
                                <li className="list-group-item">
                                     <b>Agency</b> <h6 className="float-right">{user.agencies }</h6>
                                </li>
                                <li className="list-group-item">
                                    <b>Phone</b> <h6 className="float-right">{ user.phone }</h6>
                                </li>
                                <li className="list-group-item">
                                    <b>Dob</b> <h6 className="float-right">{user.dob}</h6>
                                </li>
                                <li className="list-group-item">
                                    <b>Role</b> <h6 className="float-right">{ user.role }</h6>
                                </li>
                                <li className="list-group-item">
                                    <b>Status</b>{user.status === 'Active'?<button className="float-right btn bg-danger">Deactivate</button>:<button className="float-right btn bg-success">Active</button>}
                                </li>
                            </ul>
                            <Link to={`/users/edit/${user._id}`} className="btn btn-warning">
                                <b>Edit</b>
                            </Link>

                        </div>
                        {/* /.card-body */}
                    </div>
                    {/* /.card */}
                </div>

                {/* /.col */}
            </div>
        </>
        :
        <Spinner/>
    )
}

export default ShowUser
