import ContentHeader from '../../components/layouts/ContentHeader'
import { getRegVehicles } from '../../redux/actions/regVehiclesAction'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import GeneratePdf from '../../components/GeneratePdf'
import { useDispatch } from 'react-redux'
import { Fragment, useState } from 'react'
import Pagination from '../../components/Pagination'
// import Spinner from '../../components/Spinner'
import { Link } from 'react-router-dom'

function RegisterVehicles() {
    //select option for entries
    const selectOption = [10, 25, 50, 100];

    const {
        user,
        total,
        loading,
        setPageNumber,
        data: reg_vehicles,
        start,
        end,
        numberOfPages,
        pagination,
        pages,
        pageNumber,
        handleChange,
        goToNext,
        goToPrev,
        limit,
        search,
        count,
        setSearch
    } = Pagination(state => state.regVehicleReducer.reg_vehicles, state => state.regVehicleReducer, state => state.authReducer.loggedInUser, getRegVehicles, useState, useDispatch)

    console.log(pagination, pageNumber, pages)
    // if (loading) {
    //     return <Spinner />
    // }

    return (
        <Fragment>
            <ContentHeader title='Accident Crash DB v1' content='All Register Vehicle' />

            <div className='row'>
                <div className='col-12'>
                    <div className='card'>
                        <div className='card-header'>
                            <h3 className='card-title '>All Registered Vehicles</h3>
                            <div className="text-right">
                                {loading ? (
                                    <div>Loading...</div>
                                ) : (
                                    <>
                                        <ReactHTMLTableToExcel
                                            id="test-table-xls-button"
                                            className="download-table-xls-button btn-sm btn-success"
                                            table="example1"
                                            filename="register_vehicles"
                                            sheet="register_vehicles"
                                            // buttonText="Export XLS"
                                            buttonText={<i className="far fa-file-excel"> Export XLS</i>}
                                        /> &nbsp;
                                        {/* <button onClick={() => exportPDF(reg_vehicles)} className="download-table-xls-button btn-sm btn-danger"><i className="fa fa-file-pdf" /> Export PDF</button> */}

                                        {/* Export PDF function */}
                                        <button onClick={() => {
                                            GeneratePdf(
                                                reg_vehicles.map(vehicle => [vehicle.plateNumber, vehicle.ownerName, vehicle.county, vehicle.type, vehicle.color, vehicle.make, vehicle.model, vehicle.year]),
                                                [["Plate NO.", "Owner Name", "County", "Receipt NO.", "Type", "Color", "Make", "Model", "Year"]],
                                                "Register Vehicles Report",
                                                "register_vehicles.pdf"
                                            )
                                        }} className="download-table-xls-button btn-sm btn-danger">
                                            <i className="fa fa-file-pdf" /> Export PDF
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className='card-body table-responsive-md'>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-12 d-inline-block pb-1">
                                    <div className="dataTables_length d-inline">
                                        <span>Show</span>
                                        <select name="" defaultValue={limit} onChange={(e) => handleChange(e)} className="custom-select custom-select-sm form-control form-control-sm mx-2"
                                            style={{ width: '70px' }}>
                                            {selectOption.map((option, index) => (
                                                <option key={index} value={option}>{option}</option>
                                            ))}
                                        </select>
                                        <span>entries</span>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-12 d-inline-block" style={{ textAlign: 'right' }}>
                                    <div id="example1_filter" className="dataTables_filter float-right">
                                        <label style={{ fontWeight: 'normal', whiteSpace: 'nowrap', textAlign: 'left' }}>Search:<input
                                            type="search" className="form-control form-control-sm" aria-label='search' value={search} onChange={(e) => setSearch(e.target.value)}
                                            style={{ marginLeft: '0.5em', display: 'inline-block', width: 'auto' }} />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <br />
                            {loading ? <center>Loading...</center> :
                                <>
                                    <table id='example1' className='table-sm table-bordered table-striped' style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                {/* <th>ID #</th> */}
                                                <th>Plate NO.</th>
                                                <th>Owner Name</th>
                                                <th>County</th>
                                                <th>Receipt NO.</th>
                                                <th>Type</th>
                                                <th>Color</th>
                                                <th>Make</th>
                                                <th>Model</th>
                                                <th>Year</th>
                                                <th className='center'>
                                                    <i className='far fa-ellipsis-v-alt' />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reg_vehicles &&
                                                reg_vehicles.map((vehicle, index) => (
                                                    <tr key={vehicle._id}>
                                                        {/* <td>{index + 1}</td> */}
                                                        <td><Link to={`/register-vehicles/${vehicle._id}`} className='text-bold'>{vehicle.plateNumber}</Link></td>
                                                        <td>{vehicle.ownerName}</td>
                                                        <td>{vehicle.county}</td>
                                                        <td>{vehicle.LRAReceiptNumber}</td>
                                                        <td>{vehicle.type}</td>
                                                        <td>{vehicle.color}</td>
                                                        <td>{vehicle.make}</td>
                                                        <td>{vehicle.model}</td>
                                                        <td>{vehicle.year}</td>
                                                        <td className='text-center'>
                                                            <Link to={`/register-vehicles/${vehicle._id}`} className='btn btn-sm btn-primary'>
                                                                <i className='far fa-eye' />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    <br />

                                    <div className="row justify-content-center">
                                        <div className="col-sm-12 col-md-5 col-12">
                                            <div className="dataTables_info" role="status" aria-live="polite">
                                                {/* {'Showing ' + pageNumber + ' to ' + count + ' of ' + Math.ceil(total) + ' entries'} */}
                                                {"Showing " + (count * pageNumber - count + 1) + " to " + count * pageNumber + " of " + total + ' entries'}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-7 col-12">
                                            <div className="dataTables_paginate paging_simple_numbers">
                                                <ul className="pagination">
                                                    <li className={"paginate_button page-item previous disabled" + (!pagination.prev ? "btn-disable" : null)}>
                                                        <Link className="page-link"
                                                            onClick={() => {
                                                                if (pagination.prev) {
                                                                    goToPrev(pagination.prev.page)
                                                                }
                                                            }}>Previous</Link>
                                                    </li>

                                                    {pages.slice(start, end).map((pageIndex) => (
                                                        <li className={"paginate_button page-item" + (pageNumber === pageIndex + 1 ? " active" : null)} key={pageIndex + 1} >
                                                            <Link key={pageIndex} onClick={() => setPageNumber(pageIndex + 1)} className="page-link">
                                                                {pageIndex + 1}
                                                            </Link>
                                                        </li>
                                                    ))}

                                                    <li className={"paginate_button page-item next" + (!pagination.next ? "btn-disable" : null)}>
                                                        <Link className="page-link" onClick={() => {
                                                            if (pagination.next) {
                                                                goToNext(pagination.next.page)
                                                            }
                                                        }}>Next</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default RegisterVehicles
