import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteUser } from '../redux/actions/usersAction'
// import $ from 'jquery';

const Modal = ({ userId, userName, modalTitle }) => {

    const dispatch = useDispatch();
    const closeRef = useRef();

    const { loading } = useSelector(state => state.userReducer)
    const handleDelete = (closeRef) => {

        dispatch(deleteUser(userId))

        // setTimeout(()=>{
        //     console.log(closeRef.current.click())
        // }, 1000)

    }


    return (
        <div className="modal fade" id="modal-default">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Delete {modalTitle} Modal</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>Are you sure to delete {userName} from the system?</p>
                    </div>
                    <div className="modal-footer justify-content-right">
                        <button type="button" ref={closeRef} className="btn btn-primary" data-dismiss="modal">No</button>
                        {loading ?
                            <p className="btn btn-primary"><i className="fa fa-spinner" /> Loading </p>
                            :
                            <button type="button" onClick={() => { handleDelete(closeRef) }} className="btn btn-danger">Yes</button>
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}


export default Modal




