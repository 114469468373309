import { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { getUser } from '../../redux/actions/usersAction';
import jwt from 'jwt-decode' 
import { logoutUser } from '../../redux/actions/authAction';

const Sidebar = () => {
  const dispatch = useDispatch()

  const { user } = useSelector(state => state.authReducer.loggedInUser);
  let { _id } = JSON.parse(localStorage.getItem('user')).user

  useEffect(() => {
    dispatch(getUser(_id));
    
    const authUser = JSON.parse(localStorage.getItem('user'));

    if (authUser) {
      
      // Decode the token to get the expiration time
      const decoded = jwt(authUser.token);
      // Get the current time
      const currentTime = new Date().getTime() / 1000;

      // // Check if the token has expired
      if (decoded.exp < currentTime) {
        console.log(true)
        // Clear the user data from local storage
        localStorage.removeItem('user');

        // Redirect the user to the login page
        window.location.href = '/';
      }else {
        console.log(false)
      }
    }else{
      dispatch(logoutUser())
    }


}, [_id, dispatch])

  return (
    <>
      <aside className='main-sidebar sidebar-dark-primary elevation-4'>
        {/* Brand Logo */}
        <Link to='/dashboard' className='brand-link'>
          <img
            src='/assets/img/AdminLTELogo.png'
            alt='Logo'
            className='brand-image img-circle elevation-3'
            style={{ opacity: '.8' }}
          />
          <span className='brand-text font-weight-black'>Accident Crash</span>
        </Link>
        {/* Sidebar */}
        <div className='sidebar'>
          {/* Sidebar user panel (optional) */}
          <div className='user-panel mt-3 pb-3 mb-3 d-flex'>
            <div className='image'>
              <img
                src='/assets/img/user2-160x160.jpg'
                className='img-circle elevation-2'
                alt=''
              />
            </div>
            <div className='info'>
              <Link to='/users/profile' className='d-block'>
                {user.firstName + " " + user.lastName}
              </Link>
            </div>
          </div>
          <nav className='mt-2'>
            <ul
              className='nav nav-pills nav-sidebar flex-column'
              data-widget='treeview'
              role='menu'
              data-accordion='false'
            >
              {/* DASHBOARD */}
              <li className='nav-item'>
                <Link to='/dashboard' className='nav-link'>
                  <i className='nav-icon fas fa-tachometer-alt' />
                  <p>Dashboard</p>
                </Link>
              </li>

              {/* USERS ROUTE */}
              {/* <li className='nav-header'>USER INFORMATION</li> */}
              {(user.role === 'Admin' || user.role === 'Dev') ?
                <li className="nav-item">
                  <Link to="#" className="nav-link">
                    <i className="nav-icon fas fa-users"></i>
                    <p>
                      Users Account
                      <i className="right fas fa-angle-left"></i>
                    </p>
                  </Link>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/users" exact="true" className="nav-link">
                        <i className="far fa-copy nav-icon"></i>
                        <p>All Users</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/users/adduser" exact="true" className="nav-link">
                        <i className="far fa-copy nav-icon"></i>
                        <p>Add User</p>
                      </Link>
                    </li>
                  </ul>
                </li>
                : ''}

              {/* ACCIDENT ROUTE */}
              {/* <li className='nav-header'>ACCIDENT INFORMATION</li> */}
              <li className='nav-item'>
                <Link to='/accidents' className='nav-link'>
                  <i className='nav-icon fas fa-car-crash' />
                  <p>Accidents</p>
                </Link>
              </li>

              {/* LICENSE */}
              <li className="nav-item">
                <Link to="/licenses" className="nav-link">
                  <i className="nav-icon fas fa-id-card" />
                  <p>License</p>
                </Link>
              </li>

              {/* VEHICLE REG */}
              <li className='nav-item'>
                <Link to='/register-vehicles' className='nav-link'>
                  <i className='nav-icon fas fa-hospital-user' />
                  {/* <i className="nav-icon fa fa-person-booth"></i> */}
                  <p>Vehicle Registrations</p>
                </Link>
              </li>

              {/* MAP */}
              <li className='nav-item'>
                <Link to='/dashboard/live-map' className='nav-link'>
                  <i className='nav-icon fas fa-map-marker-alt' />
                  <p>Live Map</p>
                </Link>
              </li>

              {/* ACCOUNT MANAGER */}
              <li className='nav-header'>ACCOUNT MANAGER</li>
              <li className='nav-item'>
                <Link to='/dashboard/logs' className='nav-link'>
                  <i className="nav-icon fas fa-exclamation" />
                  <p>Logging Activity</p>
                </Link>
              </li>

              <li className='nav-item'>
                <Link to='/users/profile#settings' className='nav-link'>
                  <i className='nav-icon fas fa-user-cog' />
                  <p>Settings</p>
                </Link>
              </li>
              
              <li className='nav-item'>
                <Link to='/users/profile' className='nav-link'>
                  <i className='nav-icon fas fa-user' />
                  <p>Profile</p>
                </Link>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </>
  );
};

export default Sidebar;
