import ContentHeader from '../../components/layouts/ContentHeader'
import {storeUser, reset} from '../../redux/actions/usersAction'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from "react"
// import Spinner from "../../components/Spinner"
import { useNavigate } from 'react-router-dom'
import { toast } from "react-toastify"


const AddUser = () => {
  // get useNavigate to route to another page
  const navigate = useNavigate()

  // get useDispatch to dispatch actions
  const dispatch = useDispatch()

  // get the necessary info from the state
  const { userAction, error } = useSelector(state=> state.userReducer)

  // login user info
  const loggedInUser = useSelector(state => state.authReducer.loggedInUser.user);

  // create a loading local state
  const [loading, setLoading] = useState(false);

  // define the new user date
  const [addUser, setAddUser] = useState({
    // batchNumber: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    dob: "",
    email: "",
    agencies: "",
    status: "",
    role: "",
    password: "",
    confirmPassword: "",
    photo: "",
  });

  const {
    // batchNumber,
    firstName,
    middleName,
    lastName,
    phone,
    dob,
    email,
    agencies,
    status,
    role,
    password,
    confirmPassword,
    photo,
  } = addUser;


  const handleOnChange = (e) =>{
    setAddUser((prevState) =>({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }
  useEffect(() => {
    if (error){
      toast.error(error)
      setLoading(false)
      dispatch(reset())
    }
  }, [error, dispatch]);


  const handleSubmit = (e) =>{
    setLoading(true)
      e.preventDefault()
      const userData = {
        // batchNumber,
        firstName,
        middleName,
        lastName,
        phone,
        dob,
        email,
        agencies,
        status,
        role,
        password,
        photo,
    }
    dispatch(storeUser(userData))
  }


  if (userAction){
       navigate('/users')
  }


  return (
    <>
    <ContentHeader title='All Accidents' content='All Accidents' />

      <section>
        <div className="">
          <div id="multple-step-form-n" className=" overflow-hidden">
            <div id="multistep-start-row" className="row">
              <div id="multistep-start-column" className="col-12 m-auto">
                <form
                  id="main-form"
                  className="multisteps-form__form"
                  encType="multipart/form-data"
                >
                  <div
                    id="single-form-next"
                    className="multisteps-form__panel shadow p-4 rounded bg-white js-active"
                    data-animation="scaleIn"
                  >
                    <h3 className="multisteps-form__title">Add User Form</h3>
                    <div className="multisteps-form__content">
                      <div id="input-grp-double" className="form-row mt-4">
                        <div className="col-12 col-sm-4">
                          {/* first name input field */}
                          <label>First Name </label>
                          <input
                            type="text"
                            name="firstName"
                            value={firstName}
                            onChange={handleOnChange}
                            className="form-control multisteps-form__input"
                            placeholder="Hannah"
                          />
                        </div>
                        <div className="col-12 col-sm-4">
                          {/* middle name input field */}
                          <label>Middle Name</label>
                          <input
                            type="text"
                            name="middleName"
                            value={middleName}
                            onChange={handleOnChange}
                            className="form-control multisteps-form__input"
                            placeholder="Faith"
                          />
                        </div>
                        <div className="col-12 col-sm-4">
                          {/* last name input field */}
                          <label>Last Name</label>
                          <input
                            type="text"
                            name="lastName"
                            value={lastName}
                            onChange={handleOnChange}
                            className="form-control multisteps-form__input"
                            placeholder="Richards"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="multisteps-form__content">
                      <div id="input-grp-double-1" className="form-row mt-4">
                        <div className="col-12 col-sm-4">
                          {/* email input field */}
                          <label>Email</label>
                          <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={handleOnChange}
                            className="form-control multisteps-form__input"
                            placeholder="hannahrichards@gmail.com"
                          />
                        </div>
                        <div className="col-12 col-sm-4">
                         {/*phone input field */}
                         <label>Phone Number</label>
                           <input
                             type="tel"
                             name="phone"
                             value={phone}
                             onChange={handleOnChange}
                             className="form-control multisteps-form__input"
                             placeholder="0770777777"
                           />
                         </div>
                        <div className="col-12 col-sm-4">
                          {/* phone input field */}
                          <label>Date of Birth</label>
                          <input
                            type="date"
                            name="dob"
                            value={dob}
                            onChange={handleOnChange}
                            className="form-control multisteps-form__input"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="multisteps-form__content">
                      <div id="input-grp-double-4" className="form-row mt-4">
                      <div className="col-12 col-sm-4">
                          {/* county of deployement select field */}
                          <label>Agencies</label>
                          <select
                            name="agencies"
                            className="custom-select"
                            id="agency"
                            value={agencies}
                            onChange={handleOnChange}
                          >
                            {(loggedInUser.role === 'Dev')? (
                                <>
                                  <option>Select</option>
                                  <option>LNP</option>
                                  <option>MPW</option>
                                  <option>MOT</option>
                                  <option>MOH</option>
                                </>
                            ):<><option>Select</option><option>{loggedInUser.agencies}</option></>}
                          </select>
                        </div>
                        <div className="col-12 col-sm-4">
                          {/* status select field */}
                          <label>Status</label>
                          <select
                            name="status"
                            className="custom-select"
                            id="status"
                            value={status}
                            onChange={handleOnChange}
                          >
                            <option>Select</option>
                            <option>Active</option>
                            <option>NotActive</option>
                            <option>Suspended</option>
                          </select>
                        </div>
                        <div className="col-12 col-sm-4">
                          {/* status select field */}
                          <label>Role</label>
                          <select
                            name="role"
                            className="custom-select"
                            id="role"
                            value={role}
                            onChange={handleOnChange}
                          >
                            <option>Select</option>
                            {(loggedInUser.role === 'Dev')? (<option>Dev</option>):null}
                            <option>SuperAdmin</option>
                            <option>Dev</option>
                            <option>Admin</option>
                            <option>DataEntry</option>
                            <option>User</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="multisteps-form__content">
                      <div id="input-grp-double-4" className="form-row mt-4">
                        <div className="col-12 col-sm-4">
                          {/* officer photo input field */}
                          <label>Profile</label>
                          <input
                            className="form-control multisteps-form__input"
                            type="file"
                            name="photo"
                            onChange={handleOnChange}
                            value={photo}
                            // onChange={handleOfficerImage}
                          />
                        </div>
                        <div className="col-12 col-sm-4">
                          {/* officer photo input field */}
                          <label>Password</label>
                          <input
                            className="form-control multisteps-form__input"
                            type="password"
                            name="password"
                            onChange={handleOnChange}
                            value={password}
                            // onChange={handleOfficerImage}
                          />
                        </div>
                        <div className="col-12 col-sm-4">
                          {/* officer photo input field */}
                          <label>Confirm Password</label>
                          <input
                            className="form-control multisteps-form__input"
                            type="password"
                            name="confirmPassword"
                            onChange={handleOnChange}
                            value={confirmPassword}
                            // onChange={handleOfficerImage}
                          />
                        </div>
                      </div>
                      {/*<div className="col-12 col-sm-4">
                        {/* officer photo input field */}
                        {/*<label>Photo</label>
                        <input
                          className="form-control multisteps-form__input"
                          type="file"
                          name="image"
                          filename="image"
                          // onChange={handleOfficerImage}
  />
                      </div>*/}
                    </div>
                    <div className="multisteps-form__content">
                      <div id="input-grp-double-2" className="form-row mt-4">
                        {loading ?
                            <p
                                className="float-right btn btn-primary submitButton mb-2"
                            >
                              <i className="fa fa-spinner"/>Loading...
                            </p>
                        :
                            <button
                                type="submit"
                                onClick={handleSubmit}
                                className="float-right btn btn-primary submitButton mb-2"
                            >
                              Add User
                            </button>
                        }

                        {/*</div>*/}
                        {/*<div className="col-12 col-sm-3 text-center"></div>*/}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddUser;
